import { skipToken } from "@reduxjs/toolkit/query";
import { useGetBrewMenusByBMSubIdQuery } from "../../../api/brewMenu/brewMenuMenuApi";
import { useBrewmenuSubscription } from "../useBrewmenuSubscription";
import { brewMenuSubscriptionToken } from "../utilities/brewMenuSubscriptionToken";

export const useBrewMenuMenuList = () => {
	const { brewMenuSubscription } = useBrewmenuSubscription();

	const { data, isError, isFetching, isLoading, isSuccess, refetch } =
		useGetBrewMenusByBMSubIdQuery(
			brewMenuSubscriptionToken(brewMenuSubscription) ?? skipToken,
		);

	return {
		menuList: data?.items || [],
		getAllBMMenuRefetch: refetch,
		isErrorBrewMenuMenuList: isError,
		isFetchingBrewMenuMenuList: isFetching,
		isLoadingBrewMenuMenuList: isLoading,
		isSuccessBrewMenuMenuList: isSuccess,
	};
};
