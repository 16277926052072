export const createBrewMenuCustomProduct = /* GraphQL */ `mutation CreateBrewMenuCustomProduct($input: inputCreateCustomProduct!) {
	createBrewMenuCustomProduct(input: $input){
		createdBy
		createdDate
		id
		lastUpdatedBy
		productType
		servings {
			isActive
			isDefault
			label
			oz
			pourSize
			price
			servingType
			showServingType
		}
		subtitle
		title
		updatedDate
		beverage {
			... on Beer {
				color
				srm
				ibu
				abv
				style {
					srmMin
					srmMax
					title
					label
					slug
					description
				}

			}
			... on Kombucha {
				kombuchaFamily
				abv
				color
			}
			... on Cider {
				ciderFamily
				color
				abv
			}
			... on Seltzer {
				abv
			}
			... on Cocktail {
				abv
			}
			... on Coffee {
				abv
			}
			... on Wine {
				abv
			}
			... on Mead {
				abv
			  }
		}
	}
}`;
