

export const superAdminGetUsers = `
query SuperAdminGetUsers($input: inputSuperAdminGetUsers) {
	superAdminGetUsers(input: $input){
		nextToken
        items {
            address {
                city
                country
                geo {
                lat
                lon
                title
                }
                postalCode
                state
                street
                street2
            }
            bdbAccess
            brewlogixSuperadmin
            trustedUser
            contact {
                email
                phone
            }
            contactPreference
            createdBy
            createdDate
            defaultSubscription
            description
            familyName
            favorites {
                brands
                locations {
                brandId
                locationId
                }
                products {
                brandId
                productId
                }
            }
            givenName
            id
            jobTitle
            lastUpdatedBy
            meta
            mmbBrands {
                brandId
                createdBy
                createdDate
                lastUpdatedBy
                role
                updatedDate
            }
            ppSubscriptions {
                createdBy
                createdDate
                lastUpdatedBy
                ppSubId
                role
                updatedDate
            }
            bmSubscriptions {
                createdBy
                createdDate
                lastUpdatedBy
                bmSubId
                role
                updatedDate
            }
            organization {
                createdBy
                createdDate
                lastUpdatedBy
                organizationId
                role
                updatedDate
            }
            routes {
                coordinates {
                lat
                lon
                title
                }
                createdBy
                createdDate
                description
                distance
                duration
                id
                lastUpdatedBy
                routeId
                title
                updatedDate
            }
            slug
            updatedDate
        }
		returnedCount
		totalResults
	}
}
`