export const getDiscountPresetByIdQuery = /* GraphQL */ `query GetDiscountPresetById($input: inputGetById!) {
	getDiscountPresetById(input: $input){
		createdBy
		createdDate
		dateTimeActive {
			anyDate
			days
			endDate
			# hoursAvailable { ...hoursAvailable }
			startDate
		}
		fixedAmount
		id
		lastUpdatedBy
		percent
		pourSizes
		title
		type
		updatedDate
	}
}`;
