"use client";
import { KegConnection } from "types";
import { useGetPreviouslyReceivedKegsQuery } from "../../api";
import { usePPSub } from "../../state";

interface UsePreviouslyReceivedKegsProps {
	previouslyReceivedKegConnection: KegConnection;
	isErrorPreviouslyReceivedKegs: boolean;
	isFetchingPreviouslyReceivedKegs: boolean;
	isLoadingPreviouslyReceivedKegs: boolean;
	isSuccessPreviouslyReceivedKegs: boolean;
}

export const usePreviouslyReceivedKegs =
	(timeFrameDays = 365): UsePreviouslyReceivedKegsProps => {
		const { ppSubId } = usePPSub()

		const { data, isError, isFetching, isLoading, isSuccess } =
			useGetPreviouslyReceivedKegsQuery({ ppSubId, receivedInLastXDays: timeFrameDays }, { skip: !ppSubId });

		return {
			previouslyReceivedKegConnection: data,
			isErrorPreviouslyReceivedKegs: isError,
			isFetchingPreviouslyReceivedKegs: isFetching,
			isLoadingPreviouslyReceivedKegs: isLoading,
			isSuccessPreviouslyReceivedKegs: isSuccess,
		};
	};
