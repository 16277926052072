import { useGetDiscountPresetByIdQuery } from "../../../api";

export const useDiscountPresetById = (itemId: string) => {
	const {
		data: discountPresetById,
		isLoading: isLoadingDiscountPresetById,
		isError: isErrorDiscountPresetById,
		isFetching: isFetchingDiscountPresetById,
		isSuccess: isSuccessDiscountPresetById,
	} = useGetDiscountPresetByIdQuery({ itemId }, { skip: !itemId });

	return {
		discountPresetById,
		isLoadingDiscountPresetById,
		isErrorDiscountPresetById,
		isFetchingDiscountPresetById,
		isSuccessDiscountPresetById,
	};
};
