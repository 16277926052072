import { GraphQLError } from "graphql";
import request from "graphql-request";
import GraphQLResponse from "../types/GraphQLResponse";
import { getAToken } from "./getAToken";

const unauthorizedErrorMessage = "Auth token invalid or missing.";

// Type is not exported correctly from rtk
export type QueryReturnValue<T = unknown, E = unknown, M = unknown> =
	| {
			error: E;
			data?: undefined;
			meta?: M;
	  }
	| {
			error?: undefined;
			data: T;
			meta?: M;
	  };

const queryName = (query: string): string => {
	let queryName = "Unknown Query";

	const queryNameMatch: RegExpExecArray | null =
		/query|mutation\s+[a-zA-z]+(?:\(\$input\:\s*[a-zA-z]+\!\))?\s+[{]/.exec(
			query,
		);

	if (queryNameMatch) {
		const queryList = queryNameMatch[0].trim().split(/\s+/);

		if (queryList.length > 1) queryName = queryList[1].replace("($input:", "");
	}

	return queryName;
};

export async function graphqlBaseQuery<InputType, DataType>({
	query,
	args,
}: {
	query: string;
	args?: InputType;
}): Promise<
	QueryReturnValue<
		GraphQLResponse<DataType>,
		GraphQLError,
		undefined | { query: string }
	>
> {
	const endpoint = process.env.NEXT_PUBLIC_AWS_ENDPOINT;
	try {
		const token = await getAToken();
		if (!token) throw new Error(unauthorizedErrorMessage);
		const headers = {
			Authorization: token,
		};
		const res = await request<GraphQLResponse<DataType>>(
			endpoint,
			query,
			{ input: args },
			headers,
		);
		if (res?.errors && !res.data) {
			// @TODO: handle this in DD or console instead of UI
			console.error("gql error in response:", res?.errors);
		}
		return {
			data: Object?.values(res)[0],
		};
	} catch (error) {
		try {
			return {
				error,
				meta: {
					query: queryName(query),
				},
			};
		} catch (_) {
			return {
				error,
				meta: {
					query: "Unknown Query",
				},
			};
		}
	}
}
