export const getBrandById = /* GraphQL */ `
query GetBrandById($input: inputGetById!) {
  getBrandById(input: $input) {
      __typename
      id
      slug
      title
      displayTitle
      description
      updatedDate
      traits {
        status
        isContract
        isIndependent
        brewTypes
        brandTypes
      }
      address {
          street
          street2
          city
          state
          country
          postalCode
      }
      assets {
        __typename
        id
        alt
        ... on S3Asset {
          path
        }
        ... on CloudinaryAsset {
            cloudinaryId
        }
	    }
      website
      socialMedia {
        handle
        platform
      }
      updatedDate
      yearEstablished
        locations {
          assets {
            __typename
            id
            alt
            ... on S3Asset {
              path
            }
            ... on CloudinaryAsset {
                cloudinaryId
            }
          }
          brand {
              id
              slug
          }
          id
          slug
          title
          description
          address {
              street
              street2
              city
              state
              country
              postalCode
          }
          contact {
              phone
              email
          }
          traits {
              status
              type
              isProductionFacility
              foodOptions
              seatingOptions
              isPrimary
              foodOptions
              seatingOptions
              gameActivity
              miscActivity
              entertainmentActivity
              educationalActivity
              physicalActivity
              patronOptions
              tourInfo {
                  mustBeLegalDrinkingAge
                  cost
                  hours {
                      day
                      isOpen
                      hours
                  }
              }
          }
          hours {
              day
              isOpen
              hours
          }
          timezone
          yearOpened
          yearClosed
          updatedDate
          createdDate
        }
        products {
          __typename
          id
          title
          slug
          abv
          color
          isNewProduct
          brand {
            featuredBrew {
              id
            }
          }
          traits {
              availability
          }
          assets {
            __typename
            id
            alt
            ... on S3Asset {
              path
            }
            ... on CloudinaryAsset {
                cloudinaryId
            }
          }
          ... on Beer {
            style {
              id
              title
              label
              description
              slug
            }
          }
          ... on Kombucha {
            kombuchaFamily
          }
          ... on Cider {
            ciderFamily
          }
        }
      }
    }
`;
