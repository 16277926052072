const getPPSubByIdOptionQuery = /* GraphQL */ `
query getPPSubById($input: inputGetByPPSubId!) {
    getPPSubById(input: $input) {
        __typename
        id
        title
        slug
        address {
            city
            state
        }
        tapSensors {
            id
        }
    }
}
`;
export default getPPSubByIdOptionQuery;
