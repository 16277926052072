"use client";
import { useParams } from "next/navigation";
import { useCallback, useState } from "react";
import { PerformancePlatformSubscription } from "types";
import { useGetPPSubscriptionQuery } from "../../api";
import { useAppDispatch } from "../store";
import { setActiveId } from "../user";
import { ppSubActions } from "./ppSubState";

export interface UsePPSub {
	isFetchingPPSub: boolean;
	ppSubId: string;
	// refetchPPSub: () => void
	isSuccessPPSub: boolean;
	isLoadingPPSub: boolean;
	isPPSubError: boolean;
	ppSubApiData: PerformancePlatformSubscription;
}

export const usePPSub = (
	activePPSub?: PerformancePlatformSubscription,
): UsePPSub => {
	// const storeActivePPSubId = useAppSelector(activeSubs)?.pp
	const params = useParams<{ ppSubId: string }>();
	const dispatch = useAppDispatch();
	const [loadingPPSub, setLoadingPPSub] = useState(false);

	const {
		data,
		isError: isPPSubError,
		isFetching: isFetchingPPSub,
		isLoading: isLoadingPPSub,
		isSuccess: isSuccessPPSub,
		refetch: refetchPPSub,
	} = useGetPPSubscriptionQuery(
		{ ppSubId: activePPSub?.id ?? params?.ppSubId },
		{ skip: !activePPSub?.id && !params?.ppSubId },
	);

	useCallback(() => {
		if (activePPSub.id) {
			setLoadingPPSub(true);
			dispatch;
			ppSubActions.populatePPSubState({ ppSub: activePPSub });
			dispatch(setActiveId({ key: "pp", id: params?.ppSubId }));
			setLoadingPPSub(false);
		}
		setLoadingPPSub(false);
	}, [activePPSub]);

	return {
		// refetchPPSub,
		isSuccessPPSub: !!activePPSub?.id,
		isLoadingPPSub: loadingPPSub,
		isPPSubError: false,
		ppSubApiData: activePPSub || data,
		isFetchingPPSub: isFetchingPPSub,
		ppSubId: params?.ppSubId ?? activePPSub?.id,
	};
};
