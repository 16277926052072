"use client";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { useParams } from "next/navigation";
import { Keg } from "types";
import { useGetInventoryKegsQuery } from "../../../api";
import { useBLXUser } from "../../user";
import { InventoryState } from "../inventoryState";

export interface UseInventoryKegsHook extends Partial<InventoryState> {
	isFetching: boolean;
	isLoading: boolean;
	isSuccess: boolean;
	isError: boolean;
	status: QueryStatus;
	inventoryKegs: Keg[];
}

export const useInventoryKegs = (): UseInventoryKegsHook => {
	const params = useParams<{ ppSubId: string }>();

	const { activePPSubId } = useBLXUser();
	const ppSubId = params?.ppSubId ?? activePPSubId;

	const { data, isLoading, isError, isFetching, isSuccess, status } =
		useGetInventoryKegsQuery({ ppSubId: ppSubId }, { skip: !ppSubId });

	return {
		inventoryKegs: data,
		isLoading,
		isSuccess,
		isError,
		isFetching,
		status
	};
};
