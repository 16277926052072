import { GraphQLError } from "graphql";

const datadogErrorLog = (fileName, apiKey) => (error: GraphQLError, meta) => {
	if (process.env.NODE_ENV !== "production") {
		console.groupCollapsed(
			`Collapsed Error Log: RTKQ/transformErrorResponse/${apiKey}`,
		);
		console.debug(error);
		console.groupCollapsed("Log Data");
		console.log("File:".padEnd(10), fileName);
		console.log("API Key:".padEnd(10), apiKey);
		console.log("Query:".padEnd(10), meta?.query || "Unknown Query");
		console.groupEnd();
		console.groupEnd();
	}

	/*
     * Removing datadog implementation due to CORs errors
    datadogLogs.logger.error(
        error?.message || 'Unknown Error',
        {
            fileName,
            apiKey,
            query: meta?.query || 'Unknown Query',
        },
        error
    )
     */
};

export const datadogLocalErrorLog = (fileName) => (apiKey) =>
	datadogErrorLog(fileName, apiKey);
