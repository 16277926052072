import { sortOrgsByField } from "utilities";
import { useOrganizationSearchStore } from "./useOrganizationSearchStore";
import { useSearchOrganizations } from "./useSearchOrganizations";

export const useSortedOrganizations = () => {
	const { sortDir, sortingBy } = useOrganizationSearchStore();
	const { organizations } = useSearchOrganizations();

	return sortOrgsByField(organizations, sortDir, sortingBy);
};
