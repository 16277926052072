export const updateUserMutation = /* GraphQL */ `
mutation UpdateUser($input: updateUser!) {
	updateUser(input: $input) {
		__typename
    	id
		slug
		givenName
		familyName
		contact {
			email
			phone
		}
		ppSubscriptions {
			ppSubId
			role
		}
		mmbBrands {
			brandId
			role
		}
		description
		bdbAccess
		brewlogixSuperadmin
		meta
		address {
			street
			street2
			city
			state
			postalCode
			country
		}
	}
}`;
