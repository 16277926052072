export const searchBrandsQuery = /* GraphQL */ `
query SearchBPL($input: inputSearchBPL!) {
    searchBPL(input: $input) {  
        nextToken
        returnedCount
        totalResults
        items {
            __typename
            id  
            slug
            title
            ...on Brand {
                address {
                    city
                    country
                    postalCode
                    state
                    street
                    street2
                }
                baId
                contact {
                    email
                    phone
                }
                createdBy
                createdDate
                description
                displayTitle
                featuredBrew {
                    abv
                    alcoholFree
                    # assets { ...assets }
                    # brand { ...brand }
                    carbonation
                    color
                    createdBy
                    createdDate
                    description
                    # foodPairingList { ...foodPairingList }
                    guid
                    hintsOfFlavorNotes
                    id
                    # ingredients { ...ingredients }
                    isHiddenExternally
                    isNewProduct
                    isVerified
                    lastUpdatedBy
                    # locationRelationships { ...locationRelationships }
                    # locationsAvailable { ...locationsAvailable }
                    primaryFlavorNotes
                    releaseDate
                    servingTemperature
                    servingVessels
                    slug
                    # style { ...style }
                    tastingExperience
                    title
                    # traits { ...traits }
                    updatedDate
                    vintage
                }
                guid
                id
                lastUpdatedBy
                locations {
                    # address { ...address }
                    # assets { ...assets }
                    # brand { ...brand }
                    completionPercentage
                    # contact { ...contact }
                    createdBy
                    createdDate
                    description
                    guid
                    # hours { ...hours }
                    id
                    lastUpdatedBy
                    # links { ...links }
                    # locationHighlights { ...locationHighlights }
                    # productRelationships { ...productRelationships }
                    # productsAvailable { ...productsAvailable }
                    slug
                    subtitle
                    timezone
                    title
                    # traits { ...traits }
                    updatedDate
                    yearClosed
                    yearOpened
                }
                mediaLibrary {
                    alt
                    # brand { ...brand }
                    bytes
                    caption
                    category
                    createdBy
                    createdDate
                    height
                    id
                    lastUpdatedBy
                    # locations { ...locations }
                    path
                    # products { ...products }
                    shared
                    tags
                    type
                    updatedDate
                    width
                }
                organization {
                    # address { ...address }
                    # billing { ...billing }
                    # brands { ...brands }
                    # brewMenuSubscriptions { ...brewMenuSubscriptions }
                    cloudinaryAssetId
                    # contact { ...contact }
                    createdBy
                    createdDate
                    externalId
                    hubspotId
                    id
                    lastUpdatedBy
                    # logo { ...logo }
                    # owner { ...owner }
                    # ppSubscriptions { ...ppSubscriptions }
                    slug
                    status
                    title
                    updatedDate
                    # users { ...users }
                }
                products {
                    abv
                    alcoholFree
                    # assets { ...assets }
                    # brand { ...brand }
                    carbonation
                    color
                    createdBy
                    createdDate
                    description
                    # foodPairingList { ...foodPairingList }
                    guid
                    hintsOfFlavorNotes
                    id
                    # ingredients { ...ingredients }
                    isHiddenExternally
                    isNewProduct
                    isVerified
                    lastUpdatedBy
                    # locationRelationships { ...locationRelationships }
                    # locationsAvailable { ...locationsAvailable }
                    primaryFlavorNotes
                    releaseDate
                    servingTemperature
                    servingVessels
                    slug
                    # style { ...style }
                    tastingExperience
                    title
                    # traits { ...traits }
                    updatedDate
                    vintage
                }
                slug
                socialMedia {
                    handle
                    platform
                }
                subtitle
                title
                traits {
                    brandStatus
                    brandTypes
                    brewTypes
                    isContract
                    isIndependent
                    status
                }
                updatedDate
                website
                yearEstablished
            }
            assets {
                __typename
                id
                alt
                ... on S3Asset {
                path
                }
                ... on CloudinaryAsset {
                    cloudinaryId
                }
            }
            
        }
    }
}
`;
