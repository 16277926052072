export const deletePPSubMutation = /* GraphQL */ `
mutation DeletePPSub($input: deletePPSub!) {
  deletePPSub(input: $input) {
    id
    lastUpdatedBy
    locationType
    slug
    title
    updatedDate
  }
}
`;
