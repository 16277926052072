import { OrganizationFields, SortDirection } from "types";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type Data = {
	sortingBy: OrganizationFields;
	sortDir: SortDirection;
	searchTerm: string;
	page: number;
};

type Actions = {
	setSortingBy: (field: OrganizationFields) => void;
	setSortDir: (dir: SortDirection) => void;
	resetSorting: () => void;
	setSearchTerm: (term: string) => void;
	pageUp: () => void;
	pageDown: () => void;
};

const defaultData: Data = {
	sortingBy: OrganizationFields.Title,
	sortDir: SortDirection.Desc,
	searchTerm: "",
	page: 1,
};

export const useOrganizationSearchStore = create<Data & Actions>()(
	devtools((set, get) => ({
		...defaultData,
		setSortingBy: (field) => set({ sortingBy: field }),
		setSortDir: (dir) => set({ sortDir: dir }),
		resetSorting: () =>
			set({ sortingBy: defaultData.sortingBy, sortDir: defaultData.sortDir }),
		setSearchTerm: (term) => set({ searchTerm: term }),
		pageUp: () => set({ page: get().page + 1 }),
		pageDown: () => set({ page: get().page - 1 }),
	})),
);
