import { useGetAllBeerStylesQuery } from "../../../api";

export const useGetAllBeerStyles = () => {
	const { data, isError, isFetching, isLoading, isSuccess } =
		useGetAllBeerStylesQuery({
			limit: 1000,
		});

	return {
		beerStyles: data?.items || [],
		isErrorBrewStyles: isError,
		isFetchingBrewStyles: isFetching,
		isLoadingBrewStyles: isLoading,
		isSuccessBrewStyles: isSuccess,
	};
};
