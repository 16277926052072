import { getPace } from "utilities";
import { useTapSensors } from "./useTapSensors";

export const useDepletionPace = () => {
	const { tapSensorsApiData: sensors } = useTapSensors();

	return {
		fastPace:
			sensors?.filter(
				(sensor) =>
					getPace(sensor?.tappedKeg?.depletionStats?.depletionPace) === "Fast",
			) ?? [],
		onPace:
			sensors?.filter(
				(sensor) =>
					getPace(sensor?.tappedKeg?.depletionStats?.depletionPace) ===
					"On Pace",
			) ?? [],
		slowPace:
			sensors?.filter(
				(sensor) =>
					getPace(sensor?.tappedKeg?.depletionStats?.depletionPace) === "Slow",
			) ?? [],
	};
};
