export const getBrewMenuSubscriptions = /* GraphQL */ `
query GetBrewMenuSubscriptions($input: inputGetBrewMenuSubscriptions!) {
	getBrewMenuSubscriptions(input: $input){
		items {
			__typename
			id
			hubSpotId
			title
			subscriptionTier
			isActive
			brandId
			ppSubId
			locationIds
			organization {
				id
				title
			}
			settings {
				address {
					street
					street2
					city
					state
					country
					postalCode
					geo {
						lat
						lon
						title
					}
				}
				contact {
					phone
					email
				}
			}
			createdDate
			updatedDate
			createdBy
			lastUpdatedBy
		}
		returnedCount
		nextToken
	}
}
`;

export default getBrewMenuSubscriptions;
