"use client";

import { useContext } from "react";
import { DatadogLogsContext } from "./DatadogLogsProvider";
import { DatadogRumContext } from "./DatadogRumProvider";
import { DatadogUserContext } from "./DatadogUserProvider";

/**
 * Custom hook for using the Datadog RUM and Logs instances.
 *
 * @returns A hook providing functions for consistent access to Datadog.
 * @property {Function} setUser - A function for setting the user.
 * @property {Function} logError - A function for logging errors.
 */
export const useDatadog = () => {
	const rum = useContext(DatadogRumContext);
	const logs = useContext(DatadogLogsContext);
	const { getUser, setUser: setUserContext } = useContext(DatadogUserContext);

	const setUser = (userId) => {
		if (getUser() === userId) return;

		setUserContext(userId);

		if (rum()) {
			rum().setUser({
				id: userId ?? "Unknown User",
			});
		}

		if (logs()) {
			logs().setUser({
				id: userId ?? "Unknown User",
			});
		}
	};

	const logError = (message, error) => {
		if (logs()) {
			if (error) logs().logger.error(message, error);
			else logs().logger.error(message);
		}
	};

	return {
		setUser,
		logError,
	};
};
