import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OrganizationGroup } from "types";

interface OrgGroupState {
  // TODO: ENG-2716 - Update for slugs
  // orgGroupSlug: OrganizationGroup['slug'];
  orgGroupSlug: string;
  bmSubId: OrganizationGroup['bmSubId'];
  brandId: OrganizationGroup['brandId'];
  ppSubId: OrganizationGroup['ppSubId'];
  title: OrganizationGroup['title'];
  id: OrganizationGroup['id'];
  cloudinaryAssetId: OrganizationGroup['cloudinaryAssetId'];
}

const initialState: OrgGroupState = {
  orgGroupSlug: '',
  bmSubId: '',
  brandId: '',
  ppSubId: '',
  title: '',
  id: '',
  cloudinaryAssetId: '',
};


const orgGroupSlice = createSlice({
  name: "active-org-group",
  initialState,
  reducers: {
    setActiveOrgGroup: (state, action: PayloadAction<OrganizationGroup>) => {
      state.bmSubId = action.payload.bmSubId;
      state.brandId = action.payload.brandId;
      state.ppSubId = action.payload.ppSubId;
      state.title = action.payload.title;
      state.id = action.payload.id;
      state.cloudinaryAssetId = action.payload.cloudinaryAssetId;
    },
  }
});

export const {
  setActiveOrgGroup
} = orgGroupSlice.actions;

export default orgGroupSlice;