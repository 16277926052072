import {
	InputCreateDiscountPreset,
	InputDeleteDiscountPreset,
	InputUpdateDiscountPreset,
} from "types";
import {
	useCreateDiscountPresetMutation,
	useDeleteDiscountPresetMutation,
	useUpdateDiscountPresetMutation,
} from "../../../api";

export const useDiscountPresetMutations = () => {
	const [createDiscountPresetMutation] = useCreateDiscountPresetMutation();
	const [deleteDiscountPresetMutation] = useDeleteDiscountPresetMutation();
	const [updateDiscountPresetMutation] = useUpdateDiscountPresetMutation();

	const createDiscountPreset = async (input: InputCreateDiscountPreset) => {
		await createDiscountPresetMutation({
			...input,
		});
	};

	const deleteDiscountPreset = async (input: InputDeleteDiscountPreset) => {
		await deleteDiscountPresetMutation({
			...input,
		});
	};

	const updateDiscountPreset = async (input: InputUpdateDiscountPreset) => {
		await updateDiscountPresetMutation({
			...input,
		});
	};

	return {
		createDiscountPreset,
		deleteDiscountPreset,
		updateDiscountPreset,
	};
};
