export const updatePPSubMutation = /* GraphQL */ `
mutation updatePPSub ($input: updatePPSub!) {
  updatePPSub (input: $input) {
    id
    title
    slug
    numberOfTapSensors
    onlyPriorityBrands
    address {
        country
        postalCode
        city
        state
        street
        street2
    }       
    settings {
      batteryReplacementPlan
      coolers
      couplers
      freshnessThreshold
      inventoryTurnsGoal
      isActive
      newProductDefinition
      pricingMethod
      pricingPercentage
      productLifeTracking
      seatingCapacity
      servingSizes
      defaultServingSize
      varietyScoreGoal
      tappedGoal
      workWeekStartDay
      timeZone
      workWeekStartDay
      standardHours {
        day
		isOpen
		hours
      }
    }
    createdDate
    expirationDate
    updatedDate
  }
}
`;
