import React from "react";
import { useGetBrewMenuByIdQuery } from "../../../api";
import { useBrewmenuSubscription } from "../useBrewmenuSubscription";

export const useBrewMenuMenuById = (menuId: string) => {
	const { brewMenuSubscription } = useBrewmenuSubscription();
	const { data, isError, isFetching, isLoading, isSuccess, refetch } =
		useGetBrewMenuByIdQuery(
			{
				itemId: menuId,
				parentId: brewMenuSubscription?.id,
			},
			{ skip: !menuId || !brewMenuSubscription },
		);

	return {
		menu: data,
		sections: data?.sections || [],
		getBrewMenuRefetch: refetch,
		isErrorBrewMenuMenuById: isError,
		isFetchingBrewMenuMenuById: isFetching,
		isLoadingBrewMenuMenuById: isLoading,
		isSuccessBrewMenuMenuById: isSuccess,
	};
};
