export const addAssignedKegMutation = /* GraphQL */ `
mutation addAssignedKeg ($input: addAssignedKeg!) {
  addAssignedKeg(input: $input) {
    id
	tapNumber
    tappedKeg {
      id
    }
    assignedKegs {
      id
    }
  }
}
`;
