import { toastActions } from "notifications/src/toasts/state/useToasts";
import {
	InputCreateOrganizationAsset,
	InputCreateOrganizationForSelf,
	InputCreateOrganizationPricingPreset,
	InputDeleteOrganizationPricingPreset,
	InputGetOrganizationById,
	InputGetOrganizationPricingPresetById,
	InputGetOrganizationPricingPresets,
	InputOrganizationAssetById,
	InputUpdateOrganization,
	InputUpdateOrganizationAsset,
	InputUpdateOrganizationPricingPreset,
	Order,
	Organization,
	OrganizationAsset,
	PricingPreset,
	PricingPresetConnection,
} from "types";
import { createOrganizationAssetMutation } from "../mutations/private/organization/createOranizationAssetMutation";
import { createOrganizationForSelfMutation } from "../mutations/private/organization/createOrganizationForSelfMutation";
import { createOrganizationPricingPreset } from "../mutations/private/organization/createOrganizationPricingPreset";
import { deleteOrganizationPricingPreset } from "../mutations/private/organization/deleteOrganizationPricingPreset";
import { updateOrganizationAssetMutation } from "../mutations/private/organization/updateOrganizationAssetMutation";
import { updateOrganizationMutation } from "../mutations/private/organization/updateOrganizationMutation";
import { updateOrganizationPricingPreset } from "../mutations/private/organization/updateOrganizationPricingPreset";
import { superAdminGetOrganizationsQuery } from "../queries";
import { organizationQueries } from "../queries/OrganizationQueries";
import { baseApi } from "./baseApi";

const organizationApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		/**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getOrganizationById */
		getOrganizationById: builder.query<Organization, InputGetOrganizationById>({
			providesTags: ["Organization"],
			query: (input) => ({
				query: organizationQueries.getOrganizationByIdQuery,
				args: input,
			}),
			transformErrorResponse: (error) => {
				// @TODO: handle non-graphql error responses. e.g. 401  Maybe this is bespoke BE response?
				const rawError: any = error;
				const description =
					rawError?.response?.errors[0]?.message || error.message;
				toastActions.addToast({
					title: "Error Fetching Organization",
					description: String(description),
				});
				// keep for debug
				console.log({ error });
				return null;
			},
		}),
		/**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getOrganizationAssetById */
		getOrganizationAssetById: builder.query<
			Organization,
			InputOrganizationAssetById
		>({
			providesTags: ["Organization"],
			query: (input) => ({
				query: organizationQueries.getOrganizationAssetByIdQuery,
				args: input,
			}),
			transformErrorResponse: (error) => {
				// @TODO: handle non-graphql error responses. e.g. 401  Maybe this is bespoke BE response?
				const rawError: any = error;
				const description =
					rawError?.response?.errors[0]?.message || error.message;
				toastActions.addToast({
					title: "Error Fetching Organization Asset",
					description: String(description),
				});
				// keep for debug
				console.log({ error });
				return null;
			},
		}),
		/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updateOrganization */
		updateOrganization: builder.mutation<Organization, InputUpdateOrganization>(
			{
				invalidatesTags: ["Organization"],
				query: (input) => ({
					query: updateOrganizationMutation,
					args: input,
				}),
				transformResponse: ({ data }: { data: Organization }): Organization => {
					toastActions.addToast({
						title: "Settings Updated",
						variant: "success",
					});
					return data;
				},
			},
		),
		/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updateOrganization */
		updateOrganizationAsset: builder.mutation<
			Organization,
			InputUpdateOrganizationAsset
		>({
			invalidatesTags: ["Organization"],
			query: (input) => ({
				query: updateOrganizationAssetMutation,
				args: input,
			}),
			transformResponse: ({ data }: { data: Organization }): Organization => {
				toastActions.addToast({
					title: "Settings Updated",
					variant: "success",
				});
				return data;
			},
		}),
		/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/createOrganizationForSelf */
		createOrganizationForSelf: builder.mutation<
			Organization,
			InputCreateOrganizationForSelf
		>({
			invalidatesTags: ["User", "Organization"],
			query: (input) => ({
				query: createOrganizationForSelfMutation,
				args: input,
			}),
			transformResponse: (data): Organization => {
				toastActions.addToast({
					title: "Organization Created",
					variant: "success",
				});
				return data as unknown as Organization;
			},
		}),
		/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/createOrganizationAsset */
		createOrganizationAsset: builder.mutation<
			OrganizationAsset,
			InputCreateOrganizationAsset
		>({
			invalidatesTags: ["Organization"],
			query: (input) => ({
				query: createOrganizationAssetMutation,
				args: input,
			}),
			transformResponse: (data): OrganizationAsset => {
				toastActions.addToast({
					title: "Organization Asset Created",
					variant: "success",
				});
				return data as unknown as OrganizationAsset;
			},
		}),
		superAdminGetOrganizations: builder.query<Organization[], void>({
			providesTags: ["Organizations"],
			queryFn: async (arg, api, extraOptions, baseQuery) => {
				let nextToken = null;
				let results: Organization[] = [];
				do {
					const response = await baseQuery({
						query: superAdminGetOrganizationsQuery,
						args: {
							limit: 750,
							order: Order.Desc,
							orderBy: "createdDate",
							nextToken,
						},
					});
					results = [...results, ...response.data.items];
					nextToken = response?.data?.nextToken;
				} while (nextToken);
				return { data: results };
			},
		}),
		getOrganizationPricingPresets: builder.query<
			PricingPresetConnection,
			InputGetOrganizationPricingPresets
		>({
			providesTags: ["Pricing"],
			query: (input) => ({
				query: organizationQueries.getOrganizationPricingPresets,
				args: input,
			}),
			transformResponse: (
				data: PricingPresetConnection,
			): PricingPresetConnection => {
				return data;
			},
		}),
		getOrganizationPricingPresetById: builder.query<
			PricingPresetConnection,
			InputGetOrganizationPricingPresetById
		>({
			providesTags: ["Pricing"],
			query: (input) => ({
				query: organizationQueries.getOrganizationPricingPresetById,
				args: input,
			}),
			transformResponse: (
				data: PricingPresetConnection,
			): PricingPresetConnection => {
				return data;
			},
		}),
		createOrganizationPricingPreset: builder.mutation<
			PricingPreset,
			InputCreateOrganizationPricingPreset
		>({
			invalidatesTags: ["Pricing"],
			query: (input) => ({
				query: createOrganizationPricingPreset,
				args: input,
			}),
			transformResponse: (data: PricingPreset): PricingPreset => {
				return data;
			},
		}),
		updateOrganizationPricingPreset: builder.mutation<
			PricingPreset,
			InputUpdateOrganizationPricingPreset
		>({
			invalidatesTags: ["Pricing"],
			query: (input) => ({
				query: updateOrganizationPricingPreset,
				args: input,
			}),
			transformResponse: (data: PricingPreset): PricingPreset => {
				return data;
			},
		}),
		deleteOrganizationPricingPreset: builder.mutation<
			PricingPreset,
			InputDeleteOrganizationPricingPreset
		>({
			invalidatesTags: ["Pricing"],
			query: (input) => ({
				query: deleteOrganizationPricingPreset,
				args: input,
			}),
			transformResponse: (data: PricingPreset): PricingPreset => {
				return data;
			},
		}),
	}),
});

const {
	useGetOrganizationByIdQuery,
	useLazyGetOrganizationByIdQuery,
	useUpdateOrganizationMutation,
	useSuperAdminGetOrganizationsQuery,
	useGetOrganizationPricingPresetsQuery,
	useGetOrganizationPricingPresetByIdQuery,
	useCreateOrganizationPricingPresetMutation,
	useDeleteOrganizationPricingPresetMutation,
	useUpdateOrganizationPricingPresetMutation,
} = organizationApi;

const updateOrganization =
	organizationApi.endpoints.updateOrganization.initiate;

export {
	organizationApi,
	updateOrganization,
	useCreateOrganizationPricingPresetMutation,
	useDeleteOrganizationPricingPresetMutation,
	useGetOrganizationByIdQuery,
	useGetOrganizationPricingPresetByIdQuery,
	useGetOrganizationPricingPresetsQuery,
	useLazyGetOrganizationByIdQuery,
	useSuperAdminGetOrganizationsQuery,
	useUpdateOrganizationMutation,
	useUpdateOrganizationPricingPresetMutation,
};
