export const getBrewMenuCustomProductsByBMSubId = /* GraphQL */ `query GetBrewMenuCustomProductsByBMSubId($input: inputByBMSubId!) {
	getBrewMenuCustomProductsByBMSubId(input: $input){
		items {
			__typename
			createdBy
			createdDate
			id
			lastUpdatedBy
			productType
			title
			updatedDate
			beverage {
				... on Beer {
					color
					srm
					ibu
					abv
					style {
						srmMin
						srmMax
						title
						label
						slug
						description
					}

				}
				... on Kombucha {
					kombuchaFamily
					abv
					color
				}
				... on Cider {
					ciderFamily
					color
					abv
				}
				... on Seltzer {
				abv
			}
			... on Cocktail {
				abv
			}
			... on Coffee {
				abv
			}
			... on Wine {
				abv
			}
			... on Mead {
				abv
			  }
			}
			# servings { ...servings }
			subtitle
		}
		nextToken
		returnedCount
	}
}`;
