import type {
	InputGetAll,
	InputGetByPpSubId,
	InputSuperAdminCreatePpSub,
	InputSuperAdminUpdatePpSub,
	PerformancePlatformSubscription,
	PpSubscriptionConnection,
	UpdatePpSub,
} from "types";
import { datadogLocalErrorLog } from "../datadog/datadogErrorLogger";
import { createPPSubMutation } from "../mutations/private/ppSub/createPPSubMutation";
import { deletePPSubMutation } from "../mutations/private/ppSub/deletePPSubMutation";
import getPPSubByIdOptionQuery from "../queries/private/ppSubscription/getPPSubByIdOptionQuery";
import { getPPSubByIdQuery } from "../queries/private/ppSubscription/getPPSubByIdQuery";
import superAdminGetPPSubscriptionsQuery from "../queries/private/ppSubscription/superAdminGetPPSubscriptionsQuery";
import { superAdminUpdatePPSubMutation } from "../queries/private/ppSubscription/superAdminUpdateppSubMutation";
import { updatePPSubMutation } from "../queries/private/ppSubscription/updatePPSubMutation";
import { ppSubActions, setActiveId, userActions } from "../state";
import { baseApi } from "./baseApi";

const ppSubApiLog = datadogLocalErrorLog("ppSubApi");

const ppSubApi = baseApi.injectEndpoints({
	overrideExisting: true,
	endpoints: (builder) => ({
		/*  https://blx-docs-mono-dev.vercel.app/docs/private/query/getPPSubById **/
		getPPSubscription: builder.query<
			PerformancePlatformSubscription,
			InputGetByPpSubId
		>({
			providesTags: ["PPSub"],
			query: (input) => ({
				query: getPPSubByIdQuery,
				args: input,
				keepUnusedDataFor: 9999999,
				invalidatesTags: [
					"AssociatedSubscriptions",
					"TapSensors",
					"Kegs",
					"KegById",
					"throughput",
				],
			}),
			transformResponse: async (
				data: PerformancePlatformSubscription,
			): Promise<PerformancePlatformSubscription> => {
				ppSubActions.populatePPSubState({ ppSub: data });

				setActiveId({ key: "pp", id: data?.id });

				return data;
			},
			transformErrorResponse: ppSubApiLog("getPPSubscription"),
		}),
		getAssociatedSubscription: builder.query<
			PerformancePlatformSubscription,
			InputGetByPpSubId
		>({
			/*  https://blx-docs-mono-dev.vercel.app/docs/private/query/getPPSubById **/
			providesTags: ["AssociatedSubscriptions"],
			query: (input) => ({
				query: getPPSubByIdOptionQuery,
				args: input,
			}),
			transformResponse: (
				data: PerformancePlatformSubscription,
			): PerformancePlatformSubscription => {
				userActions.setUserPPSubAssociations(data);
				// toastActions.addToast({ title: 'Populated Associated Users' })
				return data;
			},
			transformErrorResponse: ppSubApiLog("getAssociatedSubscription"),
		}),
		/*  https://blx-docs-mono-dev.vercel.app/docs/private/query/superAdminGetPPSubscriptions **/
		superAdminGetPPSubscriptions: builder.query<
			PpSubscriptionConnection,
			InputGetAll
		>({
			providesTags: ["SuperAdminPPSubs"],
			query: (input) => ({
				query: superAdminGetPPSubscriptionsQuery,
				args: input,
				keepUnusedDataFor: 9999999,
				invalidatesTags: ["SuperAdminPPSubs"],
			}),
			transformErrorResponse: ppSubApiLog("superAdminGetPPSubscriptions"),
		}),
		/**
		 * @deprecated Use superAdminUpdatePPSubscription instead
		 */
		updatePPSubscription: builder.mutation<
			PerformancePlatformSubscription,
			UpdatePpSub
		>({
			/*  https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updatePpSubscription **/
			invalidatesTags: ["PPSub"],
			query: (input) => ({
				query: updatePPSubMutation,
				args: input,
			}),
			transformErrorResponse: ppSubApiLog("updatePPSubscription"),
		}),
		superAdminUpdatePPSubscription: builder.mutation<
			PerformancePlatformSubscription,
			InputSuperAdminUpdatePpSub
		>({
			// https://blx-docs-mono-dev.vercel.app/docs/private/mutation/superAdminUpdatePPSub
			invalidatesTags: ["PPSub", "SuperAdminPPSubs"],
			query: (input) => ({
				query: superAdminUpdatePPSubMutation,
				args: input,
			}),
			transformErrorResponse: ppSubApiLog("superAdminUpdatePPSubscription"),
		}),
		deletePPSub: builder.mutation<{ id: string }, string>({
			invalidatesTags: ["PPSub", "SuperAdminPPSubs"],
			query: (input) => ({
				query: deletePPSubMutation,
				args: {
					ppSubId: input,
				},
			}),
		}),
		superAdminCreatePPSub: builder.mutation<
			{ id: string },
			InputSuperAdminCreatePpSub
		>({
			invalidatesTags: ["SuperAdminPPSubs"],
			query: (input) => ({
				query: createPPSubMutation,
				args: input,
			}),
		}),
	}),
});

const {
	useGetPPSubscriptionQuery,
	useLazyGetPPSubscriptionQuery,
	useGetAssociatedSubscriptionQuery,
	useUpdatePPSubscriptionMutation,
	useSuperAdminGetPPSubscriptionsQuery,
	useLazySuperAdminGetPPSubscriptionsQuery,
	useDeletePPSubMutation,
	useSuperAdminCreatePPSubMutation,
} = ppSubApi;

export {
	ppSubApi, useDeletePPSubMutation, useGetAssociatedSubscriptionQuery,
	useGetPPSubscriptionQuery,
	useLazyGetPPSubscriptionQuery,
	useLazySuperAdminGetPPSubscriptionsQuery, useSuperAdminCreatePPSubMutation, useSuperAdminGetPPSubscriptionsQuery,
	useUpdatePPSubscriptionMutation
};

