export const updateMenuSectionMutation = /* GraphQL */ `mutation UpdateMenuSection($input: inputUpdateMenuSection!) {
	updateMenuSection(input: $input){
		createdBy
		createdDate
		id
		lastUpdatedBy
		title
		updatedDate
		menuItemIds
		hiddenOnDisplays
	}
}`;
