"use client";
import { useParams } from "next/navigation";
import React from "react";
import { Keg } from "types";
import { useGetUnassignedKegsQuery } from "../../../api/inventoryApi";

export interface UseUnassignedKegsHook {
	isFetching: boolean;
	isLoading: boolean;
	isSuccessUnassignedKegs: boolean;
	isError: boolean;
	refetchUnassignedKegs: () => void;
	unassignedKegs: Keg[];
}

export const useUnassignedKegs = (): UseUnassignedKegsHook => {
	const params = useParams<{ ppSubId: string }>();
	const activePPSubId = params?.ppSubId;

	const {
		data: unassignedKegs,
		isLoading,
		isError,
		isFetching,
		isSuccess,
		refetch: refetchUnassignedKegs,
	} = useGetUnassignedKegsQuery({ ppSubId: activePPSubId });

	React.useEffect(() => {
		if (!unassignedKegs) {
			refetchUnassignedKegs();
		}
	}, []);

	return {
		refetchUnassignedKegs,
		unassignedKegs,
		isLoading,
		isSuccessUnassignedKegs: isSuccess,
		isError,
		isFetching,
	};
};
