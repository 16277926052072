export const getThroughputByKegId = `
query GetThroughputByKegId($input: inputGetThroughputByKegId!) {
	getThroughputByKegId(input: $input){
		keg {
			actualKickDate
			assignedPositionIndex
			# assignedTapSensor { ...assignedTapSensor }
			bornOnDate
			cooler
			cost
			coupler
			couplerType
			createdBy
			createdDate
			currentFluidOz
			defaultServingSize
			depletionPace
			depletionRate
			# depletionStats { ...depletionStats }
			expirationDate
			id
			initialFluidOz
			isEstimate
			isPartial
			isTemporary
			kegStatus
			lastUpdatedBy
			# pricingPreset { ...pricingPreset }
			# product { ...product }
			receivedDate
			servingSizes
			# servings { ...servings }
			tappedDate
			unexpectedWeightIncreases
			updatedDate
			# vessel { ...vessel }
		}
		throughputDetails {
			throughput
			timestamp
		}
		totalThroughput
	}
}`;
