"use client";

import {
	DefaultPrivacyLevel,
	RumGlobal,
	datadogRum,
} from "@datadog/browser-rum";
import React, { useEffect } from "react";

export const DatadogRumContext = React.createContext(null);

export const DatadogRumProvider = ({ children, shouldInit }) => {
	const rum = React.useRef<RumGlobal | null>(null);

	const initializeRum = React.useCallback(() => {
		datadogRum.init({
			applicationId: String(
				process.env.NEXT_PUBLIC_DATADOG_BLAP_APPLICATION_ID,
			),
			clientToken: String(process.env.NEXT_PUBLIC_DATADOG_BLAP_CLIENT_TOKEN),
			site: "datadoghq.com",
			service: "blx-app",
			env: String(process.env.NODE_ENV),
			version: String(process.env.NEXT_PUBLIC_APP_VERSION),
			sessionSampleRate: 100,
			sessionReplaySampleRate: 20,
			trackUserInteractions: true,
			trackResources: true,
			trackLongTasks: true,
			defaultPrivacyLevel: DefaultPrivacyLevel.ALLOW,
			allowedTracingUrls: [process.env.NEXT_PUBLIC_AWS_ENDPOINT ?? ""],
			enableExperimentalFeatures: ["feature_flags"],
		});

		datadogRum.startSessionReplayRecording();

		rum.current = datadogRum;

		return rum.current;
	}, []); // Empty dependency array ensures this is only created once

	useEffect(() => {
		if (shouldInit && !rum.current) initializeRum();
	}, [shouldInit, initializeRum]); // Run effect only when shouldInit or initializeRum changes

	const getRum = React.useCallback((): RumGlobal => {
		if (shouldInit && !rum.current) return initializeRum();
		return rum.current;
	}, [shouldInit, initializeRum]); // Memoize to ensure getRum is stable

	return (
		<DatadogRumContext.Provider value={getRum}>
			{children}
		</DatadogRumContext.Provider>
	);
};
