import { toastActions } from "notifications/src/toasts/state/useToasts";
import {
	InputGetThroughputByKegId,
	InputGetThroughputByPpSub,
	InputGetThroughputByProduct,
	KegThroughput,
	PpSubThroughputDetails,
	PpSubThroughputSummary,
	ProductThroughput,
	ProductThroughputDetail,
} from "types";
import { getThroughputByKegId } from "../queries/private/throughput/getThroughputByKegId";
import { getThroughputByProduct } from "../queries/private/throughput/getThroughputByProduct";
import { getThroughputDetailsByPPSub } from "../queries/private/throughput/getThroughputDetailsByPPSub";
import { getThroughputSummaryByPPSub } from "../queries/private/throughput/getThroughputSummaryByPPSub";
import { baseApi } from "./baseApi";

const throughPutApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getThroughputByProduct: builder.query<
			ProductThroughputDetail[],
			InputGetThroughputByProduct
		>({
			providesTags: ["ThroughPutByProduct", "throughput"],
			query: (input) => ({
				query: getThroughputByProduct,
				args: { ...input },
			}),
			transformResponse: (
				data: ProductThroughput,
			): ProductThroughputDetail[] => {
				// TODO: Add notifications
				return data?.productThroughput || [];
			},
			transformErrorResponse: () =>
				toastActions.addToast({
					title: "There was an issue getting the throughput by product",
				}),
		}),
		getThroughputByKegId: builder.query<
			KegThroughput,
			InputGetThroughputByKegId
		>({
			providesTags: ["ThroughputByKegId"],
			query: (input) => ({
				query: getThroughputByKegId,
				args: { ...input },
			}),
			transformErrorResponse: () =>
				toastActions.addToast({
					title: "There was an issue getting the throughput by keg id",
				}),
		}),
		getThroughputDetailsByPPSub: builder.query<
			PpSubThroughputDetails,
			InputGetThroughputByPpSub
		>({
			query: (input) => ({
				query: getThroughputDetailsByPPSub,
				args: input,
			}),
		}),
		getThroughputSummaryByPPSub: builder.query<
			PpSubThroughputSummary,
			InputGetThroughputByPpSub
		>({
			query: (input) => ({
				query: getThroughputSummaryByPPSub,
				args: input,
			}),
		}),
	}),
});

const {
	useGetThroughputByProductQuery,
	useGetThroughputByKegIdQuery,
	useGetThroughputDetailsByPPSubQuery,
	useGetThroughputSummaryByPPSubQuery,
} = throughPutApi;

export {
	throughPutApi,
	useGetThroughputByKegIdQuery,
	useGetThroughputByProductQuery,
	useGetThroughputDetailsByPPSubQuery,
	useGetThroughputSummaryByPPSubQuery,
};
