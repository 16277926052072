"use client";

import { Box } from "@chakra-ui/react";
import { useToasts } from "../state/useToasts";
import Toast from "./Toast";

const ToastProvider: React.FC = () => {
	const { toasts } = useToasts();
	return (
		<Box
			position="fixed"
			zIndex="30"
			display="flex"
			flexDirection="column"
			gap={4}
			alignItems="start"
			justifyContent="start"
			left={12}
			width="full"
			height="auto"
			bottom={12}
			minWidth={32}
			maxW="md"
		>
			{toasts.map((toast) => {
				return (
					<Toast
						key={toast?.id}
						id={toast?.id}
						title={toast?.title}
						description={toast?.description}
						hasCloseButton={toast?.hasCloseButton}
						variant={toast?.variant}
						duration={toast?.duration}
					/>
				);
			})}
		</Box>
	);
};

export default ToastProvider;
