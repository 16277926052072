import Fuse from "fuse.js";
import { Organization } from "types";

export const orgSearchKeys: Fuse.FuseOptionKey<Organization>[] = [
	{ name: "id" },
	{ name: "title" },
	{ name: "hubSpotId" },
	{ name: "owner.contact.email" },
	{ name: "brands.title" },
	{ name: "brewMenuSubscriptions.title" },
	{ name: "ppSubscriptions.title" },
];
