export const createBrewMenuSubscriptionMutation = /* GraphQL */ `mutation CreateBrewMenuSubscription($input: inputCreateBrewMenuSubscription!) {
    createBrewMenuSubscription(input: $input) {
        createdBy
        createdDate
        id
        isActive
        lastUpdatedBy
        locationIds
        slug
        subscriptionTier
        title
        updatedDate
    }
}`;
