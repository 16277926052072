import { PerformancePlatformSubscription } from "types";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

export interface IPPSubState {
	ppSubId: string;
	ppSub: PerformancePlatformSubscription;
}

export interface IPPSubActions {
	setPPSubId: ({ ppSubId }: { ppSubId: string }) => void;
	setPPSubData: ({
		ppSubData,
	}: { ppSubData: PerformancePlatformSubscription }) => void;
	resetPPSubState: () => void;
	populatePPSubState: ({
		ppSub,
	}: { ppSub?: PerformancePlatformSubscription }) => void;
}

const initialValues: IPPSubState = {
	ppSubId: "",
	ppSub: null,
};

export const ppSubState = create<IPPSubState & IPPSubActions>()(
	devtools(
		(set) => ({
			...initialValues,
			setPPSubId: ({ ppSubId }) => {
				set({ ppSubId });
			},
			setPPSubData: ({ ppSubData }) => {
				set({ ppSub: ppSubData });
			},
			populatePPSubState: ({ ppSub }) => {
				set({
					ppSub,
					ppSubId: ppSub?.id,
				});
			},
			resetPPSubState: () => {
				set({ ...initialValues });
			},
		}),
		{ name: "ppSubState" },
	),
);

export const ppSubActions = {
	setPPSub: ppSubState.getState().setPPSubId,
	setPPSubData: ppSubState.getState().setPPSubData,
	populatePPSubState: ppSubState.getState().populatePPSubState,
	resetPPSubState: ppSubState.getState().resetPPSubState,
};
