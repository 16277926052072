export const createProductMutationInventory = /* GraphQL */ `mutation createProduct ($input: createProduct!) {
    createProduct(input: $input) {
        id
        abv
        title
        color
        ... on Unverified {
            brandTitle
        }
    }
}`;
