"use client";

import { DatadogLogsProvider } from "./DatadogLogsProvider";
import { DatadogRumProvider } from "./DatadogRumProvider";
import { DatadogUserProvider } from "./DatadogUserProvider";

// Allow more control over when to initialize the datadog instance
const shouldInit = (): boolean => {
	return true;
	// return process.env.NODE_ENV === "production";
};

/**
 * Provides a context for interacting with Datadog RUM and Logs instances.
 *
 * @component
 * @example
 * ```tsx
 * <DatadogProvider>
 *   <App />
 * </DatadogProvider>
 * ```
 */
export const DatadogProvider = ({ children }) => {
	return (
		<DatadogRumProvider shouldInit={shouldInit()}>
			<DatadogLogsProvider shouldInit={shouldInit()}>
				<DatadogUserProvider>{children}</DatadogUserProvider>
			</DatadogLogsProvider>
		</DatadogRumProvider>
	);
};
