import { Keg, KegStatus } from "types";
import { useGetAssignedKegsQuery, useGetInventoryKegsQuery, useGetTappedKegsQuery, useGetUnassignedKegsQuery } from "../../api"

export const useKegsByStatus = (ppSubId: string | undefined): {
    allKegs: Keg[],
    tappedKegs: Keg[],
    onDeckKegs: Keg[],
    unassignedKegs: Keg[],
    archivedItems: Keg[],
    isLoading: boolean
} => {
    const { data: allKegs, isLoading: loadingInventoryKegs } = useGetInventoryKegsQuery({ ppSubId }, { skip: !ppSubId });
    const { data: tappedKegs, isLoading: loadingTappedKegs } = useGetTappedKegsQuery({ ppSubId }, { skip: !ppSubId });
    const { data: onDeckKegs, isLoading: loadingAssignedKegs } = useGetAssignedKegsQuery({ ppSubId }, { skip: !ppSubId });
    const { data: unassignedKegs, isLoading: loadingUnassignedKegs } = useGetUnassignedKegsQuery({ ppSubId }, { skip: !ppSubId });

    const isLoading = loadingInventoryKegs || loadingTappedKegs || loadingAssignedKegs || loadingUnassignedKegs;
    const archivedItems = allKegs?.filter(({ kegStatus }) => kegStatus === KegStatus.Depleted);

    return {
        allKegs: allKegs || [],
        tappedKegs: tappedKegs || [],
        onDeckKegs: onDeckKegs || [],
        unassignedKegs: unassignedKegs || [],
        archivedItems: archivedItems || [],
        isLoading
    };
};