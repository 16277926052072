export const createAssetMutation = /* GraphQL */ `
mutation CreateAsset($input: inputCreateAsset!) {
  createAsset(input: $input) {
      alt
      brand {
        id
        lastUpdatedBy
        slug
        title
      }
      caption
      category
      createdBy
      createdDate
      id
      lastUpdatedBy
      locations {
        id
        title
        slug
      }
      path
      products {
        id
        title
        slug
      }
      tags
      type
      updatedDate
      bytes
      width
      height
      ... on S3Asset {
          path
          filename
      }
      ... on CloudinaryAsset {
          cloudinaryId
      }
  }
}
`;
