export const getBrewMenusQuery = /* GraphQL */ `query GetBrewMenus($input: inputGetBrewMenus!) {
  getBrewMenus(input: $input) {
    items {
      activeDates
      createdBy
      createdDate
      displays {
        createdBy
        createdDate
        displayTitle
        displayType
        id
        lastUpdatedBy
        menu {
          id
          title
        }
        slug
        status
        title
        updatedDate
      }
      id
      lastUpdatedBy
      locationId
      ppSubId
      status
      title
      updatedDate
    }
    nextToken
    returnedCount
  }
}`;
