"use client";
import React from "react";
import { MediaLibrary } from "types";
import { useGetMediaLibraryQuery } from "../../../api";
import { useAppSelector } from "../../store";
import { activeBrandSub } from "../../user";
import { MediaState, mediaState } from "../mediaState";

export interface UseMediaHook extends Partial<MediaState> {
	isFetching: boolean;
	isLoading: boolean;
	isSuccess: boolean;
	isError: boolean;
	media: MediaLibrary;
	refetch: () => void;
}

export const useMedia = (): UseMediaHook => {
	const mediaLibrary = mediaState.use.mediaLibrary();
	const brandId = useAppSelector(activeBrandSub);

	const {
		data: media,
		isLoading,
		isError,
		isFetching,
		isSuccess,
		refetch,
	} = useGetMediaLibraryQuery({ brandId }, { skip: !brandId });

	React.useEffect(() => {
		if (brandId) {
			// LEAVE FOR DEBUGGING
			// console.log('Refetching subscription in usefx')

			// Need data to be able to refresh it
			if (isSuccess) refetch();
		}
	}, [brandId]);

	return {
		media,
		mediaLibrary,
		refetch,
		isLoading,
		isSuccess,
		isError,
		isFetching,
	};
};
