export const removeAssignedKegMutation = /* GraphQL */ `
mutation removeAssignedKeg ($input: removeAssignedKeg!) {
  removeAssignedKeg(input: $input) {
    id
	tapNumber
    tappedKeg {
      id
    }
    assignedKegs {
      id
    }
  }
}
`;
