"use client";

import { LogsGlobal, datadogLogs } from "@datadog/browser-logs";
import React from "react";

export const DatadogLogsContext = React.createContext(null);

export const DatadogLogsProvider = ({ children, shouldInit }) => {
	const logs = React.useRef<LogsGlobal | null>(null);

	const initializeLogs = React.useCallback((): LogsGlobal => {
		if (!logs.current && shouldInit) {
			datadogLogs.init({
				clientToken: String(process.env.NEXT_PUBLIC_DATADOG_BLAP_CLIENT_TOKEN),
				site: "datadoghq.com",
				service: "blx-app",
				env: String(process.env.NODE_ENV),
				version: String(process.env.NEXT_PUBLIC_APP_VERSION),
				sessionSampleRate: 100,
				forwardErrorsToLogs: true,
			});

			logs.current = datadogLogs;
		}

		return logs.current;
	}, [shouldInit]); // Dependency on shouldInit

	const getLogs = React.useCallback((): LogsGlobal => {
		if (!logs.current && shouldInit) {
			return initializeLogs();
		}
		return logs.current;
	}, [initializeLogs, shouldInit]); // Dependency on initializeLogs and shouldInit

	return (
		<DatadogLogsContext.Provider value={getLogs}>
			{children}
		</DatadogLogsContext.Provider>
	);
};
