export const getPreviouslyRecievedKegs = /* GraphQL */ `query GetPreviouslyReceivedKegs($input: inputGetPreviouslyReceivedKegs!) {
  getPreviouslyReceivedKegs(input: $input) {
    items {
      actualKickDate
      assignedPositionIndex
      bornOnDate
      cooler
      cost
      coupler
      couplerType
      createdBy
      createdDate
      currentFluidOz
      defaultServingSize
      depletionPace
      depletionRate
      expirationDate
      id
      initialFluidOz
      isEstimate
      isPartial
      isTemporary
      kegStatus
      lastUpdatedBy
      vessel {
        title
        id
      }
      product {
        id
        abv
        title
        __typename
        brand {
          title
          id
        }

        assets {
          ... on S3Asset {
            __typename
            path
          }
          ... on CloudinaryAsset {
            __typename
            cloudinaryId
          }
        }
      }
      projectedKickDate
      receivedDate
      servingSizes
      tappedDate
      unexpectedWeightIncreases
      updatedDate
    }
    nextToken
    returnedCount
    totalResults
  }
}`;
