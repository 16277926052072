import { InputGetMessagesByPpSubId, InputSuperAdminDeletePpSubItem, Message, MessageConnection, Order } from "types";
import { superAdminDeleteMessage } from "../mutations/private/messages/superAdminDeleteMessage";
import { getMessagesByPPSubIdQuery } from "../queries/private/messages/getMessagesByPPSubIdQuery";
import { superAdminGetMessages } from "../queries/private/messages/superAdminGetMessages";
import { baseApi } from "./baseApi";

const messagesApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getMessages: builder.query<Message[], InputGetMessagesByPpSubId>({
			providesTags: ["Messages"],
			queryFn: async (arg, _api, _extraOptions, baseQuery) => {
				let nextToken = undefined;
				let results: Message[] = [];
				do {
					const response = await baseQuery({
						query: getMessagesByPPSubIdQuery,
						args: {
							limit: 750,
							...arg,
							nextToken,
							sortAscending: false,
						},
					});
					results = [...results, ...response.data.messages];
					nextToken = response?.data?.nextToken;
				} while (nextToken);
				return { data: results };
			}
		}),
		superAdminGetMessages: builder.query<MessageConnection, void>({
			providesTags: ["Messages"],
			query: () => ({
				query: superAdminGetMessages,
				args: {
					limit: 1000,
					orderBy: "createdDate",
					order: Order.Desc,
					filters: {
						timeResolved: {
							exists: false,
						},
					},
				},
			}),
		}),
		deleteMessage: builder.mutation<void, InputSuperAdminDeletePpSubItem>({
			invalidatesTags: ["Messages"],
			query: (input) => ({
				query: superAdminDeleteMessage,
				args: input,
			}),
		}),
	}),
});

const {
	useGetMessagesQuery,
	useSuperAdminGetMessagesQuery,
	useDeleteMessageMutation,
} = messagesApi;

export {
	messagesApi,
	useDeleteMessageMutation,
	useSuperAdminGetMessagesQuery,
	useGetMessagesQuery
};

