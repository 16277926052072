"use client";
import { useAppSelector } from "../../state/store";
import {
	makePPSubscriptionFromCache,
	ppSubscriptionCache as ppSubs,
	userBMSubscriptions,
	userMMBBrands,
	userPPSubscriptions,
} from "../../state/user/persistedUserSubscriptionsSlice";

export const useUserSubscriptions = () => {
	const ppCache = makePPSubscriptionFromCache();

	const ppSubscriptions = useAppSelector(userPPSubscriptions);
	const bmSubscriptions = useAppSelector(userBMSubscriptions);
	const mmbBrands = useAppSelector(userMMBBrands);
	const ppSubscriptionCache = useAppSelector(ppSubs);
	const ppSubscriptionFromCache = (subId) =>
		useAppSelector((state) => ppCache(state, subId));

	return {
		ppSubscriptions,
		bmSubscriptions,
		mmbBrands,
		ppSubscriptionCache,
		ppSubscriptionFromCache,
	};
};
