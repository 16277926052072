"use client";
import { useParams } from "next/navigation";
import { TapSensor } from "types";
import { useGetTapSensorsFromPPSubQuery } from "../../api/tapSensorsApi";

export interface IUseTapSensors {
	currentTapSensorsApiData?: TapSensor[];
	isFetchingtapSensors: boolean;
	refetchTapSensors: () => void;
	isSuccesstapSensors: boolean;
	isLoadingtapSensors: boolean;
	istapSensorsError: boolean;
	tapSensorsApiData: TapSensor[];
}

export const useTapSensors = (tapSensors?: TapSensor[]): IUseTapSensors => {
	const params = useParams<{ ppSubId: string }>();

	const {
		data: tapSensorsApiData,
		currentData: currentTapSensorsApiData,
		isError: istapSensorsError,
		isFetching: isFetchingtapSensors,
		isLoading: isLoadingtapSensors,
		isSuccess: isSuccesstapSensors,
		refetch: refetchTapSensors,
	} = useGetTapSensorsFromPPSubQuery(
		{ ppSubId: params.ppSubId },
		{ skip: !params?.ppSubId },
	);

	return {
		currentTapSensorsApiData,
		isSuccesstapSensors,
		isLoadingtapSensors,
		istapSensorsError,
		tapSensorsApiData: tapSensors ?? tapSensorsApiData,
		isFetchingtapSensors,
		refetchTapSensors,
	};
};
