export const createKegsMutation = /* GraphQL */ `
  mutation createKegs($input: createKegs!) {
    createKegs(input: $input) {
    id
    cooler
    kegStatus
    cost
    servingSizes
    defaultServingSize
    initialFluidOz
    currentFluidOz
    servings {
      isDefault
      oz
      price
    }
    product {
      __typename
      id
      title
      ... on Beer{
        abv
        style {
          id
          title
         }
      }
    }
    vessel {
      id
      title
      slug
    }
    }
  }
`;
