export const updateLocation = /* GraphQL */ `
mutation UpdateLocation($input: updateLocation!) {
	updateLocation(input: $input) {
		__typename
		id
		createdDate
		updatedDate
		createdBy
		lastUpdatedBy
		guid
		slug
		title
		description
		address {
			street
			street2
			city
			state
			country
			postalCode
		}
		brand {
			__typename
			id
			slug
			title
			displayTitle
			address {
				street
				street2
				city
				state
				country
				postalCode
			}
			assets {
				__typename
				id
				alt
				type
				updatedDate
				createdDate
				createdBy
				bytes 
				width
				height
				tags
				... on S3Asset {
					path
				}
				... on CloudinaryAsset {
					cloudinaryId
					caption
					category
				}
			}
		}
		assets {
			__typename
			id
			alt
			type
			updatedDate
			createdDate
			createdBy
			bytes 
			width
			height
			tags
			... on S3Asset {
				path
			}
			... on CloudinaryAsset {
				cloudinaryId
				caption
				category
			}
		}
		contact {
			phone
			email
		}
		traits {
			status
			type
			isPrimary
			brewOptions
			foodOptions
			seatingOptions
			tourInfo {
				toursAvailable
				mustBeLegalDrinkingAge
				description
				cost
				hours {
				day
				isOpen
				hours
				}
			}
			physicalActivity
			gameActivity
			educationalActivity
			entertainmentActivity
			miscActivity
			patronOptions
			nearbyAttractions {
				title
				approximateDistance
			}
			isProductionFacility
			isOpenToPublic
		}
		links {
			label
			url
			target
		}
		hours {
			day
			isOpen
			hours
		}
		timezone
		yearOpened
		yearClosed
		completionPercentage
		locationHighlights {
			headline
			customHeadline
			description
		}
	}
}
`;
