import Fuse from "fuse.js";
import { useSuperAdminGetOrganizationsQuery } from "../../api";
import { orgSearchKeys } from "./orgSearchKeys";
import { useOrganizationSearchStore } from "./useOrganizationSearchStore";

export const useSearchOrganizations = () => {
	const { data, isLoading, isFetching, refetch } =
		useSuperAdminGetOrganizationsQuery();
	const { searchTerm } = useOrganizationSearchStore();

	const fuse = new Fuse(data, {
		keys: orgSearchKeys,
		threshold: 0.25,
		useExtendedSearch: true,
	});

	const results = searchTerm
		? fuse.search(searchTerm).map(({ item }) => item)
		: data;

	return {
		organizations: results || [],
		isLoading,
		isFetching,
		refetch,
	};
};
