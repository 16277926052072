"use client";
import { useParams } from "next/navigation";
import { Keg } from "types";
import { useGetArchivedKegsQuery } from "../../../api/inventoryApi";
import {
	InventoryState,
	inventoryActions,
	inventoryState,
} from "../inventoryState";

export interface useArchivedKegsHook extends Partial<InventoryState> {
	isFetching: boolean;
	isLoading: boolean;
	isSuccess: boolean;
	isError: boolean;
	archivedKegs: Keg[];
}

export const useArchivedKegs = (): useArchivedKegsHook => {
	const selectedKeg = inventoryState.use.selectedKeg();
	const selectKeg = inventoryActions.selectKeg;

	const params = useParams<{ ppSubId: string }>();

	const {
		data: archivedKegs,
		isLoading,
		isError,
		isFetching,
		isSuccess,
	} = useGetArchivedKegsQuery(
		{ ppSubId: params?.ppSubId },
		{ skip: !params?.ppSubId },
	);

	return {
		archivedKegs,
		selectKeg,
		selectedKeg,
		isLoading,
		isSuccess,
		isError,
		isFetching,
	};
};
