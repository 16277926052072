import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
	BmSubscriptionAssociation,
	IPersistedUserSession,
	PerformancePlatformSubscription,
	User,
} from "types";

export type ActiveIdTypes = "bma" | "brand" | "pp" | "brewknowledge";
export enum PersistedSessionLoadState {
	Initial = "initial",
	Pending = "pending",
	Loaded = "loaded",
	Error = "error",
}

const defaultUser: User = {
	address: {
		city: "",
		country: "",
		postalCode: "",
		state: "",
		street: "",
		street2: "",
	},
	bmSubscriptions: [],
	boards: [],
	brewlogixSuperadmin: false,
	contact: {
		email: "",
		phone: "",
	},
	contactPreference: [],
	createdDate: "",
	defaultSubscription: "",
	description: "",
	familyName: "",
	favorites: {
		brands: [],
		favoriteBrands: [],
		favoriteLocations: [],
		favoriteProducts: [],
		locations: [],
		products: [],
	},
	givenName: "",
	id: "",
	isActive: false,
	mmbBrands: [],
	ppSubscriptions: [],
	routes: [],
	slug: "",
	trustedUser: false,
	updatedDate: "",
};

const initialState: IPersistedUserSession & {
	loadState: PersistedSessionLoadState;
} & {
	saPPSubs: PerformancePlatformSubscription[];
} = {
	user: defaultUser,
	activeIds: {
		bma: "",
		brand: "",
		pp: "",
		brewknowledge: "",
	},
	clerkId: "",
	loadState: PersistedSessionLoadState.Initial,
	saPPSubs: [],
};

export const persistedUserSlice = createSlice({
	name: "persisted-user",
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<User>) => {
			state.user = action.payload;
		},
		addUserBrewMenuSubscription: (
			state,
			action: PayloadAction<BmSubscriptionAssociation>,
		) => {
			state.user.bmSubscriptions.push(action.payload);
		},
		setActiveBMASubId: (state, action: PayloadAction<string>) => {
			state.activeIds.bma = action.payload;
		},
		setPersistedSessionLoadState: (
			state,
			action: PayloadAction<PersistedSessionLoadState>,
		) => {
			state.loadState = action.payload;
		},
		setSAPPSubs: (
			state,
			action: PayloadAction<PerformancePlatformSubscription[]>,
		) => {
			state.saPPSubs = action.payload;
		},
		setActiveIds: (
			state,
			action: PayloadAction<Record<ActiveIdTypes, string>>,
		) => {
			state.activeIds = action.payload;
		},
		setActiveId: (
			state,
			action: PayloadAction<{
				key: keyof IPersistedUserSession["activeIds"];
				id: string;
			}>,
		) => {
			state.activeIds[action.payload.key] = action.payload.id;
		},
	},
	selectors: {
		// Load State
		persistedSessionLoadState: ({ loadState }): PersistedSessionLoadState =>
			loadState,
		// User
		sessionUser: ({ user }): User => user,
		// Active Subs
		saPPSubs: ({ saPPSubs }): PerformancePlatformSubscription[] => saPPSubs,
		activeSubs: ({ activeIds }): Record<ActiveIdTypes, string> => activeIds,
		hasActiveSub: ({ activeIds }, type: ActiveIdTypes): boolean =>
			activeIds[type] && activeIds[type].length > 0,
		activeBmaSub: ({ activeIds }): string => activeIds.bma,
		activeBrandSub: ({ activeIds }): string => activeIds.brand,
		activePpSub: ({ activeIds }): string => activeIds.pp,
		activeBrewknowledgeSub: ({ activeIds }): string => activeIds.brewknowledge,
		// Clerk ID
		clerkId: ({ clerkId }): string => clerkId,
	},
});

export const {
	addUserBrewMenuSubscription,
	setActiveBMASubId,
	setActiveId,
	setActiveIds,
	setPersistedSessionLoadState,
	setSAPPSubs,
	setUser,
} = persistedUserSlice.actions;

export const {
	saPPSubs,
	sessionUser,
	activeSubs,
	hasActiveSub,
	activeBmaSub,
	activeBrandSub,
	activePpSub,
	activeBrewknowledgeSub,
	clerkId,
} = persistedUserSlice.selectors;

export default persistedUserSlice;
