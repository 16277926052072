export const getProductByIdPPAccount = /* GraphQL */ `
query getProductById($input: inputGetById!) {
  getProductById(input: $input) {
    __typename
    id
    abv
    color
    createdDate
    hintsOfFlavorNotes 
    isVerified
    isNewProduct
    primaryFlavorNotes
    releaseDate
    servingTemperature 
    servingVessels
    tastingExperience
    updatedDate
    description
    slug
    title
    foodPairingList {
      category
      title
    }
    assets {
      __typename
      id
      alt
      type
      caption
      ... on S3Asset {
          path
      }
      ... on CloudinaryAsset {
          cloudinaryId
      }
    }
    brand {
      id
      title
      displayTitle
      slug
      assets {
        __typename
        id
        alt
        type
        caption
        ... on S3Asset {
            path
        }
        ... on CloudinaryAsset {
            cloudinaryId
        }
      }
      address {
        street
        city
        state
      }
    }
    foodPairingList{
      category
      title
    }
    ingredients {
      title
      type
    }    
    traits {
      allergens
      availability
      nutrition {
        calories
        carbohydrates
        cholesterol
        dietaryFiber
        fat
        protein
        saturatedFat
        sodium
        transFat
        sugars
      }
      otherTraits
    }
    ... on Beer {
      originalGravity
			srm
			ibu
			style {
        title
        category {
            title
        }
        abvMax
        abvMin
        createdDate
        description
        fgMax
        fgMin
        foodPairingList{
          category
          title
        }
        hintsOfFlavorNotes
        hops
        ibuMax
        ibuMin
        id
        label
        malt
        ogMax
        ogMin
        primaryFlavorNotes
        servingTemperature
        servingVessels
        slug
        srmMax
        srmMin
        title
        updatedDate
        yeast
      }
    }
    ... on Kombucha {
      acidity
			appearance 
			body 
			brixCount 
			carbonation 
			kombuchaFamily 
			perceivedSweetness 
			probiotics 
			sourness
			teaCharacter 
    }
    ... on Cider {
      ciderFamily 
      acidity
      perceivedSweetness
      body
      tannin
      brixCount
      carbonation
    }
    ... on UnverifiedProduct {
      brandName
    }
  }
}
`;
