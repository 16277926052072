"use client";

import { skipToken } from "@reduxjs/toolkit/query";
import {
	BrewMenuSubscription,
	InputGetBrewMenuSubscriptionById,
	User,
} from "types";
import { useGetBrewMenuSubscriptionByIdQuery } from "../../api";
import { useBLXUser } from "../../state";

export interface useBrewmenuSubscriptionHook {
	isLoadingBrewmenuSubscription: boolean;
	isErrorBrewmenuSubscription: boolean;
	isFetchingBrewmenuSubscription: boolean;
	isSuccessBrewmenuSubscription: boolean;
	brewMenuSubscription: BrewMenuSubscription;
}

const bmSubIdToken = (
	user: User,
): { bmSubId: InputGetBrewMenuSubscriptionById["bmSubId"] } | undefined => {
	if (user && user.bmSubscriptions && user.bmSubscriptions.length > 0) {
		const bmSubId = user.bmSubscriptions[0]?.bmSubId;

		if (bmSubId) return { bmSubId };
	}

	return undefined;
};

export const useBrewmenuSubscription = (): useBrewmenuSubscriptionHook => {
	const { sessionUser } = useBLXUser();

	const {
		data: brewMenuSubscription,
		isLoading: isLoadingBrewmenuSubscription,
		isError: isErrorBrewmenuSubscription,
		isFetching: isFetchingBrewmenuSubscription,
		isSuccess: isSuccessBrewmenuSubscription,
	} = useGetBrewMenuSubscriptionByIdQuery(
		bmSubIdToken(sessionUser) ?? skipToken,
	);

	return {
		brewMenuSubscription,
		isLoadingBrewmenuSubscription,
		isErrorBrewmenuSubscription,
		isFetchingBrewmenuSubscription,
		isSuccessBrewmenuSubscription,
	};
};
