export const getDiscountPresetsQuery = /* GraphQL */ `query GetDiscountPresets($input: inputGetDiscountPresets!) {
	getDiscountPresets(input: $input){
		items {
			createdBy
			createdDate
			dateTimeActive {
				anyDate
				days
				endDate
				startDate
				hoursAvailable {
					day
					hours
					isOpen
				}
			}
			fixedAmount
			id
			lastUpdatedBy
			percent
			pourSizes
			title
			type
			updatedDate
		}
		nextToken
		returnedCount
	}
}`;
