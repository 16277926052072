import { skipToken } from "@reduxjs/toolkit/query";
import { useGetBrewMenusByBMSubIdQuery } from "../../../api/brewMenu/brewMenuMenuApi";
import { useBrewmenuSubscription } from "../useBrewmenuSubscription";
import { brewMenuSubscriptionToken } from "../utilities/brewMenuSubscriptionToken";

export const useBrewMenuDisplayMenuList = () => {
	const { brewMenuSubscription } = useBrewmenuSubscription();

	const { data, isError, isFetching, isLoading, isSuccess } =
		useGetBrewMenusByBMSubIdQuery(
			brewMenuSubscriptionToken(brewMenuSubscription) ?? skipToken,
		);

	return {
		menuList: data?.items || [],
		isErrorBrewMenuDisplayMenuList: isError,
		isFetchingBrewMenuDisplayMenuList: isFetching,
		isLoadingBrewMenuDisplayMenuList: isLoading,
		isSuccessBrewMenuDisplayMenuList: isSuccess,
	};
};
