import { InputCreateAccountRequest } from "types";
import { toastActions } from "../../../notifications/src/toasts";
import createAccountRequestMutation from "../mutations/private/account/createAccountRequestMutation";
import { baseApi } from "./baseApi";

const accountApi = baseApi.injectEndpoints({
	overrideExisting: true,
	endpoints: (builder) => ({
		createAccountRequest: builder.mutation<void, InputCreateAccountRequest>({
			query: (input) => ({
				query: createAccountRequestMutation,
				args: input,
			}),
			transformErrorResponse: () =>
				toastActions.addToast({
					title: "Error",
					description: "Request Failed",
					variant: "error",
				}),
			transformResponse: () =>
				toastActions.addToast({
					title: "Success",
					description: "Request Created",
					variant: "success",
				}),
		}),
	}),
});

const { useCreateAccountRequestMutation } = accountApi;

export { accountApi, useCreateAccountRequestMutation };
