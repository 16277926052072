export const searchBPLQuery = /* GraphQL */ `
    query searchBPL($input: inputSearchBPL!) {
  searchBPL(input: $input)  {
            items {
                __typename
                ... on Product {
                    id
                    slug
                    title
                    style {
                        label
                        kegFreshnessDuration
                    }   
                    brand {
                        id
                        title
                        displayTitle
                        assets {
                        __typename
                        id
                        alt
                        type
                        caption
                        ... on S3Asset {
                            path
                        }
                        ... on CloudinaryAsset {
                            cloudinaryId
                        }
                    }
                    }
                    assets {
                        __typename
                        id
                        alt
                        type
                        caption
                        ... on S3Asset {
                            path
                        }
                        ... on CloudinaryAsset {
                            cloudinaryId
                        }
                    }
                    ... on Beer {
                        abv
                        srm
                        style {
                            title
                            id
                        }
                    }
                    ... on Kombucha {
                        abv
                        color
                        kombuchaFamily
                    }
                    ... on Cider {
                        abv
                        ciderFamily
                    }
                    # ... on UnverifiedProduct {
                        # 	brandName
                        # 	title
                        # 	brand
                        # 	style{
                            # 		title
                            # 		id
                            # 	}
                            
                            # }
                        }
                    }
                }
            }
`;
