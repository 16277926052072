export const getThroughputSummaryByPPSub = `
query GetThroughputSummaryByPPSub($input: inputGetThroughputByPPSub!) {
	getThroughputSummaryByPPSub(input: $input){
		topBrand{
        title
      }
      topProduct {
        title
      }
      topStyle{
        title
      }
	}
}
`;
