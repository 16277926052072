import { getOrganizationAssetByIdQuery } from "./getOrganizationAssetByIdQuery";
import { getOrganizationByIdQuery } from "./getOrganizationByIdQuery";
import { getOrganizationPricingPresetById } from "./getOrganizationPricingPresetById";
import { getOrganizationPricingPresets } from "./getOrganizationPricingPresets";
import { superAdminGetOrganizationsQuery } from "./superAdminGetOrganizationsQuery";

export {
	getOrganizationPricingPresets,
	superAdminGetOrganizationsQuery,
	getOrganizationAssetByIdQuery,
	getOrganizationByIdQuery,
	getOrganizationPricingPresetById,
};
