export const updateOrganizationAssetMutation = /* GraphQL */ `mutation UpdateOrganizationAsset($input: inputUpdateOrganizationAsset!) {
	updateOrganizationAsset(input: $input){
		updatedDate
		lastUpdatedBy
		id
		createdDate
		createdBy
		cloudinaryId
		bmSubLogoAssignments {
			brandId
			createdBy
			createdDate

			hubSpotId
			id
			isActive
			lastUpdatedBy
			locationIds

			ppSubId

			slug
			subscriptionTier
			title
			updatedDate
		}
		menuItemAssetAssignments {

			category
			createdBy
			createdDate

			discountPresetIds

			glassware
			id
			isVisible
			itemId
			lastUpdatedBy
			menuItemType
			parentId

			tags

			updatedDate
		}
		organizationLogoAssignments {
			createdBy
			createdDate
			id
			lastUpdatedBy
			updatedDate

			externalId
			hubspotId

			slug
			status
			title

		}
		ppSubLogoAssignments {

			brewMenuTier
			createdBy
			createdDate
			expirationDate

			hubSpotId
			id

			lastUpdatedBy
			locationType

			numberOfTapSensors
			onlyPriorityBrands

			slug

			title
			updatedDate
		}
		shared
		tags
	}
}`;
