import { skipToken } from "@reduxjs/toolkit/query";
import { useGetBrewMenuDisplayByIdQuery } from "../../../api";
import { useBrewmenuSubscription } from "../useBrewmenuSubscription";

const token = (
	displayId: string,
	bmSubId: string,
):
	| {
			itemId: string;
			parentId: string;
	  }
	| undefined => {
	if (displayId && bmSubId) {
		return {
			itemId: displayId,
			parentId: bmSubId,
		};
	}

	return undefined;
};

export const useBrewMenuDisplayById = (displayId: string) => {
	const { brewMenuSubscription } = useBrewmenuSubscription();

	const { data, isError, isFetching, isLoading, isSuccess } =
		useGetBrewMenuDisplayByIdQuery(
			token(displayId, brewMenuSubscription?.id) ?? skipToken,
		);

	return {
		display: data,
		isErrorBrewMenuDisplayById: isError,
		isFetchingBrewMenuDisplayById: isFetching,
		isLoadingBrewMenuDisplayById: isLoading,
		isSuccessBrewMenuDisplayById: isSuccess,
	};
};
