import { useGetStylesQuery } from "../../api";

export const useBlxGroups = () => {
	const { data: styles } = useGetStylesQuery({});

	const blxGroups = styles?.items
		.map((style) => style.blxGroup)
		.filter((group) => group);

	return [...new Set(blxGroups)];
};
