export const getKegsByStatusQuery = /* GraphQL */ `
    query getKegsByStatus ($input: inputGetKegsByStatus!) {
        getKegsByStatus (input: $input) {
            items {
                id
                tappedDate
                bornOnDate
                unexpectedWeightIncreases
                assignedTapSensor {
                    id
                    tapNumber
                    transformedReading
                }
                depletionStats {
                    projectedKickDate
                    depletionPace
                    depletionRate
                }
                product {
                    __typename
                    title
                    isVerified
                    id
                    description
                    slug
                    abv
                    assets {
                        __typename
                        id
                        alt
                        type
                        caption
                        ... on S3Asset {
                            path
                        }
                        ... on CloudinaryAsset {
                            cloudinaryId
                        }
                    }
                    brand {
                        id
                        slug
                        title
                        displayTitle
                        address {
                            city
                            state
                        }
                        assets {
                            __typename
                            id
                            alt
                            type
                            caption
                            ... on S3Asset {
                                path
                            }
                            ... on CloudinaryAsset {
                                cloudinaryId
                            }
                        }
                    }
                    ... on Beer {
                        abv
                        ibu
                        srm
                        style {
                            abvMax
                            abvMin
                            blxGroup
                            createdDate
                            description
                            fgMax
                            fgMin
                            hintsOfFlavorNotes
                            hops
                            ibuMax
                            ibuMin
                            id
                            label
                            malt
                            ogMax
                            ogMin
                            primaryFlavorNotes
                            servingTemperature
                            slug
                            srmMax
                            srmMin
                            title
                            updatedDate
                            yeast
                        }
                        primaryFlavorNotes
                        traits {
                            nutrition {
                                calories
                                fat
                                saturatedFat
                                transFat
                                cholesterol
                                sodium
                                carbohydrates
                                dietaryFiber
                                sugars
                                protein
                            }
                            allergens
                            otherTraits
                            availability
                        }
                        servingTemperature
                        servingVessels
                        ingredients {
                            type
                            title
                        }
                    }
                    ... on Cider {
                        ciderFamily
                        primaryFlavorNotes                    
                    }
                    ... on Kombucha {
                        color
                        kombuchaFamily
                    
                        primaryFlavorNotes
                        traits {
                            nutrition {
                                calories
                                fat
                                saturatedFat
                                transFat
                                cholesterol
                                sodium
                                carbohydrates
                                dietaryFiber
                                sugars
                                protein
                            }
                            allergens
                            otherTraits
                            availability
                        }
                        servingTemperature
                        servingVessels
                        ingredients {
                            type
                            title
                        }
                    }
                    ... on Cider {
                        ciderFamily
                    }
                }
                actualKickDate
                updatedDate
                servingSizes
                defaultServingSize
                isEstimate
                isPartial
                isTemporary
                kegStatus
                expirationDate
                receivedDate
                createdDate
                cost
                cooler
                coupler
                initialFluidOz
                currentFluidOz
                cooler
                cost
                vessel {
                    title
                    shortTitle
                    slug
                    id
                    label
                }
            }
            nextToken
		    returnedCount
		    totalResults
        }
    }
`;
