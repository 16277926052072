export const updateLocationProductRelationshipMutation = /* GraphQL */ `
mutation UpdateLocationProductRelationship($input: inputLocationProductRelationship!) {
    updateLocationProductRelationship(input: $input) {
        available
        canned
        growler
        location {
            id
        }
        onTap
        product {
            __typename
            abv
            assets {
                __typename
                id
                alt
                type
                updatedDate
                bytes 
                width
                height
                ... on S3Asset {
                    path
                }
                ... on CloudinaryAsset {
                    cloudinaryId
                    caption
                }
            }
            brand {
                id
                title
                displayTitle
            }
            color
            id
            title
            ... on Beer {
                style {
                    title
                    label
                }
            }
            ... on Kombucha {
                kombuchaFamily
            }
            ... on Cider {
                ciderFamily
            }
        }
    }
}
`;
