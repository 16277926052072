"use client";
import { useParams } from "next/navigation";
import { Keg } from "types";
import { useGetAssignedKegsQuery } from "../../../api/inventoryApi";
import {
	InventoryState,
	inventoryActions,
	inventoryState,
} from "../inventoryState";

export interface UseAssignedKegsHook extends Partial<InventoryState> {
	isFetching: boolean;
	isLoading: boolean;
	isSuccessAssignedKegs: boolean;
	isError: boolean;
	assignedKegs: Keg[];
}

export const useAssignedKegs = (): UseAssignedKegsHook => {
	const selectedKeg = inventoryState.use.selectedKeg();
	const selectKeg = inventoryActions.selectKeg;

	const params = useParams<{ ppSubId: string }>();
	const activePPSubId = params?.ppSubId;

	const {
		data: assignedKegs,
		isLoading,
		isError,
		isFetching,
		isSuccess,
	} = useGetAssignedKegsQuery(
		{ ppSubId: activePPSubId },
		{ skip: !activePPSubId },
	);

	return {
		assignedKegs,
		selectKeg,
		selectedKeg,
		isLoading,
		isSuccessAssignedKegs: isSuccess,
		isError,
		isFetching,
	};
};
