import { useGetBrewMenuDisplaysByBMSubIdQuery } from "../../../api/brewMenu/brewMenuDisplayApi";
import { useBrewmenuSubscription } from "../useBrewmenuSubscription";

export const useBrewMenuDisplays = () => {
	const { brewMenuSubscription = { id: "" } } = useBrewmenuSubscription();

	const { data, isError, isFetching, isLoading, isSuccess } =
		useGetBrewMenuDisplaysByBMSubIdQuery(
			{
				bmSubId: brewMenuSubscription.id,
			},
			{ skip: !brewMenuSubscription },
		);

	return {
		brewMenuDisplays: (data && data.items) || [],
		isErrorBrewMenuDisplays: isError,
		isFetchingBrewMenuDisplays: isFetching,
		isLoadingBrewMenuDisplays: isLoading,
		isSuccessBrewMenuDisplays: isSuccess,
	};
};
