"use client";
import { Keg, KegStatus } from "types";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { createSelectors } from "../../utilities";

export interface InventoryInitialState {
	kegs: Keg[];
	selectedKeg: Keg;
	archivedKegs: Keg[];
	assignedKegs: Keg[];
	tappedKegs: Keg[];
	unassignedKegs: Keg[];
}

export interface InventoryActions {
	setKegs: ({
		kegs,
		kegStatus,
	}: { kegs: Keg[]; kegStatus?: KegStatus }) => void;
	addKeg: (keg: Keg) => void;
	updateKeg: (keg: Keg) => void;
	removeKeg: (kegId: string) => void;
	selectKeg: (keg: Keg) => void;
}

export type InventoryState = InventoryActions & InventoryInitialState;

const defaultValues: InventoryInitialState = {
	kegs: [],
	selectedKeg: null,
	archivedKegs: [],
	assignedKegs: [],
	tappedKegs: [],
	unassignedKegs: [],
};

export const inventoryState = createSelectors(
	create<InventoryState>()(
		devtools(
			(set, get) => ({
				...defaultValues,
				//TODO: Account for isTemporary
				setKegs: ({ kegStatus, kegs }) => {
					switch (kegStatus) {
						case KegStatus.Assigned:
							set({ assignedKegs: kegs });
							break;
						case KegStatus.Depleted:
							set({ archivedKegs: kegs });
							break;
						case KegStatus.Tapped:
							set({ tappedKegs: kegs });
							break;
						case KegStatus.Unassigned:
							set({ unassignedKegs: kegs });
							break;

						default:
							set({ kegs });
							break;
					}
					!kegStatus && set({ kegs });
					kegStatus === KegStatus.Depleted && set({ archivedKegs: kegs });
				},
				selectKeg: (keg) => set({ selectedKeg: keg }),
				addKeg: (keg: Keg) => {
					keg;
				},
				updateKeg: (keg: Keg) => {
					keg;
				},
				removeKeg: (kegId: string) => {
					("");
				},
			}),
			{ name: "inventoryState" },
		),
	),
);

export const inventoryActions = {
	setKegs: inventoryState.getState().setKegs,
	selectKeg: inventoryState.getState().selectKeg,
};
