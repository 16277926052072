export const getBrewMenuByIdQuery = /* GraphQL */ `query GetBrewMenuById($input: inputGetById!) {
	getBrewMenuById(input: $input) {
	  activeDates
	  createdBy
	  createdDate
	  displays {
		displayTitle
		displayType
		id
		slug
		status
		title
	  }
	  id
	  lastUpdatedBy
	  locationId
	  sections {
		id
		title
		updatedDate
		createdBy
		hiddenOnDisplays
		menuItems {
		  id
		  isVisible
		  customProduct {
			id
			title
			subtitle
			description
			productType
			beverage {
			  ... on Beer {
				color
				srm
				ibu
				abv
				traits {
				  otherTraits
				}
				style {
				  srmMin
				  srmMax
				  title
				  label
				  slug
				  description
				  id
				}
			  }
			  ... on Kombucha {
				kombuchaFamily
				abv
				color
				traits {
				  otherTraits
				}
			  }
			  ... on Cider {
				ciderFamily
				color
				abv
				traits {
				  otherTraits
				}
			  }
			  ... on Seltzer {
				abv
				traits {
				  otherTraits
				}
			}
			... on Cocktail {
				abv
				traits {
				  otherTraits
				}
			}
			... on Coffee {
				abv
				traits {
				  otherTraits
				}
			}
			... on Wine {
				abv
				traits {
				  otherTraits
				}
			}
			... on Mead {
				abv
				traits {
				  otherTraits
				}
			  }
			}
		  }
		  menuItemType
		  servings {
			isDefault
			oz
			price
			salePrice
			isActive
			pourSize
			servingType
			label
			isDisplayed
		  }
		  product {
			id
			slug
			title
			color
			abv
			description
			servingVessels
			__typename
			primaryFlavorNotes
			brand {
			  id
			  slug
			  title
			  displayTitle
			  address {
				city 
				state
			  }
			  assets {
			  	id
				alt
				type
				caption
				... on S3Asset {
					__typename
					path
				}
				... on CloudinaryAsset {
					__typename
					cloudinaryId
					}
				}
			}
			... on Beer {
			  srm
			  ibu
			  style {
				srmMin
				srmMax
				title
				label
				slug
				description
			  }
			}
			... on Kombucha {
			  __typename
			  kombuchaFamily
			  id
			  color
			  title
			}
			... on Cider {
			  ciderFamily
			}
			assets {
			  id
			  alt
			  type
			  caption
			  ... on S3Asset {
				__typename
				path
			  }
			  ... on CloudinaryAsset {
				__typename
				cloudinaryId
			  }
			}
		  }
		  tapSensor {
			id
			tapNumber
			assignedKegs {
			  id
			  product {
				id
				title
				color
				abv
				slug
				assets {
				  __typename
				  id
				  alt
				  type
				  caption
				  ... on S3Asset {
					path
				  }
				  ... on CloudinaryAsset {
					cloudinaryId
				  }
				}
				brand {
				  id
				  title
				  displayTitle
				  slug
				  address {
					city 
					state
			  	  }
				  assets {
					__typename
					id
					alt
					type
					caption
					... on S3Asset {
					  path
					}
					... on CloudinaryAsset {
					  cloudinaryId
					}
				  }
				}
				... on Beer {
				  id
				  abv
				  ibu
				  srm
				  style {
					title
					id
					abvMax
					abvMin
					fgMax
					fgMin
					ibuMax
					ibuMin
					hops
					malt
					ogMax
					ogMin
					srmMax
					srmMin
					yeast
				  }
				  title
				}
				... on Cider {
				  ciderFamily
				}
				... on Kombucha {
				  probiotics
				  kombuchaFamily
				  __typename
				}
			  }
			}
			tappedKeg {
			  id
			  currentFluidOz
			  initialFluidOz
			  defaultServingSize
			  vessel {
				iconSlug
			  }
			  servings {
				oz
				price
				isDefault
				pourSize
				isDisplayed
				label
				isActive
			  }
			  product {
				title
				id
				slug
				color
				abv
				description
				servingVessels
				__typename
				primaryFlavorNotes
				brand {
				  id
				  slug
				  title
				  displayTitle
				  address {
					city 
					state
			  	  }
					assets {
			  			id
						alt
						type
						caption
						... on S3Asset {
							__typename
							path
						}
						... on CloudinaryAsset {
							__typename
							cloudinaryId
						}
					}
				}
				... on Beer {
				  srm
				  ibu
				  style {
					srmMin
					srmMax
					title
					label
					slug
					description
				  }
				}
				... on Kombucha {
				  __typename
				  kombuchaFamily
				  id
				  color
				  title
				}
				... on Cider {
				  ciderFamily
				}
				assets {
				  id
				  alt
				  type
				  caption
				  ... on S3Asset {
					__typename
					path
				  }
				  ... on CloudinaryAsset {
					__typename
					cloudinaryId
				  }
				}
			  }
			}
		  }
		}
	  }
	  ppSubId
	  status
	  title
	  updatedDate
	}
  }`;
