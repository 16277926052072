import {
	BrewMenu,
	BrewMenuStatus,
	InputCreateBrewMenu,
	InputDeleteBrewMenu,
	InputUpdateBrewMenu,
} from "types";
import {
	useAddMenuItemOrSectionToBrewMenuMutation,
	useCreateBrewMenuMutation,
	useDeleteBrewMenuMutation,
	useUpdateBrewMenuMutation,
} from "../../../api/brewMenu/brewMenuMenuApi";
import { useBrewmenuSubscription } from "../useBrewmenuSubscription";

export const useBrewMenuMenuMutations = () => {
	const { brewMenuSubscription } = useBrewmenuSubscription();

	const [createMenuMutation] = useCreateBrewMenuMutation();
	const [deleteMenuMutation] = useDeleteBrewMenuMutation();
	const [updateMenuMutation] = useUpdateBrewMenuMutation();
	const [addMenuItemOrSectionMutation] =
		useAddMenuItemOrSectionToBrewMenuMutation();

	const createMenu = async (
		title: InputCreateBrewMenu["title"],
	): Promise<BrewMenu> => {
		// @ts-ignore
		const { data } = await createMenuMutation({
			bmSubId: brewMenuSubscription?.id,
			status: BrewMenuStatus.Draft,
			title,
		});

		if (data) return data;
	};

	const deleteMenu = async (brewMenuId: InputDeleteBrewMenu["brewMenuId"]) => {
		await deleteMenuMutation({
			bmSubId: brewMenuSubscription?.id,
			brewMenuId,
		});
	};

	const updateMenu = async (input: {
		brewMenuId: InputUpdateBrewMenu["brewMenuId"];
		status?: InputUpdateBrewMenu["status"];
		title?: InputUpdateBrewMenu["title"];
		sectionIds?: InputUpdateBrewMenu["sectionIds"];
	}): Promise<BrewMenu> => {
		// @ts-ignore
		const { data } = await updateMenuMutation({
			bmSubId: brewMenuSubscription?.id,
			...input,
		});
		if (data) return data;
	};

	return {
		createMenu,
		deleteMenu,
		updateMenu,
	};
};
