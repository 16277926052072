export const updateOrganizationPricingPreset = /* GraphQL */ `mutation UpdateOrganizationPricingPreset($input: inputUpdateOrganizationPricingPreset) {
	updateOrganizationPricingPreset(input: $input){
		createdBy
		createdDate
		id
		lastUpdatedBy
		updatedDate
		isDefault
		servings {
			isActive
			isDefault
			isDisplayed
			label
			oz
			pourSize
			price
			salePrice
			servingType
			showServingType
		}
		title
	}
}`;
