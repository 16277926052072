"use client";
import { useEffect } from "react";
import { Organization } from "types";
import { useGetOrganizationByIdQuery } from "../../api";
import { userState } from "../user";
import { organizationState } from "./organizationState";

export interface UseOrganization {
	isFetchingOrganization: boolean;
	organization: Organization;
	organizationId: string;
	refetchOrganization: () => void;
	isSuccessOrganization: boolean;
	isLoadingOrganization: boolean;
	isOrganizationError: boolean;
}

export const useOrganization = (): UseOrganization => {
	const orgId = userState((state) => state?.orgId);
	const activeOrganizationId = organizationState(
		(state) => state.organizationId,
	);

	const {
		data: organization,
		isError: isOrganizationError,
		isFetching: isFetchingOrganization,
		isLoading: isLoadingOrganization,
		isSuccess: isSuccessOrganization,
		refetch: refetchOrganization,
	} = useGetOrganizationByIdQuery({ organizationId: orgId }, { skip: !orgId });

	useEffect(() => {
		if (activeOrganizationId !== orgId || !activeOrganizationId) {
			if (isSuccessOrganization) refetchOrganization();
		}
	}, [orgId]);

	return {
		refetchOrganization,
		isSuccessOrganization,
		isLoadingOrganization,
		isOrganizationError,
		isFetchingOrganization,
		organization,
		organizationId: orgId,
	};
};
