import { InputUpdateBrewMenuSubscription } from "types";
import { useUpdateBrewMenuSubscriptionMutation } from "../../api";

export const useBrewmenuSubscriptionMutations = () => {
	const [updateBrewMenuSubscriptionMutation] =
		useUpdateBrewMenuSubscriptionMutation();

	const updateBrewMenuSubscription = async (
		input: InputUpdateBrewMenuSubscription,
	) => {
		await updateBrewMenuSubscriptionMutation({ ...input });
	};

	return {
		updateBrewMenuSubscription,
	};
};
