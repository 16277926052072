import { gql } from "graphql-request";

export const getVesselByIdQuery = gql`
query GetVesselById($input: inputGetById!) {
	getVesselById(input: $input){
		updatedDate
		id
		createdDate
		awrSleep
		awrWeight
		couplerWeight
		createdBy
		displayOrder
		iconSlug
		label
		lastUpdatedBy
		material
		shortTitle
		slug
		suggestedTotalVolume
		tareWeight
		title
		totalVolume
	}
}
    `;
