import { useProductSearchStore } from "./productSearchStore";
import { useSortedProductSearch } from "./useSortedProductSearch";

const PAGE_SIZE = 10;

export const usePaginatedProductSearch = () => {
	const { page } = useProductSearchStore();
	const sortedData = useSortedProductSearch();

	const pageResults = sortedData.slice(
		(page - 1) * PAGE_SIZE,
		page * PAGE_SIZE,
	);
	const totalPages = Math.ceil(sortedData.length / PAGE_SIZE);

	return {
		pageResults,
		totalPages,
	};
};
