export const updateAssetMutation = /* GraphQL */ `
mutation UpdateAsset($input: inputUpdateAsset!) {
  updateAsset(input: $input) {
    alt
    brand {
      id
      lastUpdatedBy
      slug
      title
      displayTitle
    }
    caption
    category
    createdBy
    createdDate
    id
    lastUpdatedBy
    locations {
      id
      title
      slug
    }
    path
    products {
      id
      title
      slug
    }
    tags
    type
    updatedDate
    bytes
    width
    height
    ... on S3Asset {
        path
        filename
    }
    ... on CloudinaryAsset {
        cloudinaryId
    }
  }
}
`;
