import { useGetDiscountPresetsByBMSubIdQuery } from "../../../api";
import { useBrewmenuSubscription } from "../useBrewmenuSubscription";

export const useDiscountPresetsByBMSubId = () => {
	const { brewMenuSubscription } = useBrewmenuSubscription();

	const {
		data: discountPresets,
		isLoading: isLoadingDiscountPresets,
		isError: isErrorDiscountPresets,
		isFetching: isFetchingDiscountPresets,
		isSuccess: isSuccessDiscountPresets,
	} = useGetDiscountPresetsByBMSubIdQuery(
		{ bmSubId: brewMenuSubscription?.id },
		{ skip: !brewMenuSubscription?.id },
	);

	return {
		discountPresets,
		isLoadingDiscountPresets,
		isErrorDiscountPresets,
		isFetchingDiscountPresets,
		isSuccessDiscountPresets,
	};
};
