import {
	getOrganizationAssetByIdQuery,
	getOrganizationByIdQuery,
	getOrganizationPricingPresetById,
	getOrganizationPricingPresets,
} from "./private/organization";

export enum OrganizationQueries {
	GET_ORGANIZATION_BY_ID = "getOrganizationByIdQuery",
	GET_ORGANIZATION_ASSET_BY_ID = "getOrganizationAssetByIdQuery",
	GET_ORGANIZATION_PRICING_PRESETS = "getOrganizationPricingPresets",
	GET_ORGANIZATION_PRICING_PRESET_BY_ID = "getOrganizationPricingPresetById",
}

// allow for computed string that use fragments
export const organizationQueries: Record<OrganizationQueries, string> = {
	getOrganizationByIdQuery,
	getOrganizationAssetByIdQuery,
	getOrganizationPricingPresets,
	getOrganizationPricingPresetById,
};
