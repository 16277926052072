export const removeMenuItemOrSectionFromBrewMenuMutation = /* GraphQL */ `mutation RemoveMenuItemOrSectionFromBrewMenu($input: inputBrewMenuItemOrSection!) {
	removeMenuItemOrSectionFromBrewMenu(input: $input){
		id
		lastUpdatedBy
		status
		title
		activeDates
		locationId
	}
}`;
