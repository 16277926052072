export const getLocationsQuery = /* GraphQL */ `
query GetBrandById($input: inputGetById!) {
  getBrandById(input: $input) {
      id
      slug
      title
      displayTitle
      description
      updatedDate
      traits {
        status
        isContract
        isIndependent
        brewTypes
        brandTypes
      }
      assets {
          path
          type
          alt
      }
      website
      socialMedia {
        handle
        platform
      }
      yearEstablished
      locations {
        assets {
          __typename
          id
          alt
          ... on S3Asset {
            path
          }
          ... on CloudinaryAsset {
              cloudinaryId
          }
        }
        brand {
            id
            slug
        }
        id
        slug
        title
        description
        address {
            street
            street2
            city
            state
            country
            postalCode
        }
        contact {
            phone
            email
        }
        traits {
            status
            type
            isProductionFacility
            foodOptions
            seatingOptions
            isPrimary
            foodOptions
            seatingOptions
            gameActivity
            miscActivity
            entertainmentActivity
            educationalActivity
            physicalActivity
            patronOptions
            tourInfo {
                mustBeLegalDrinkingAge
                cost
                hours {
                    day
                    isOpen
                    hours
                }
            }
        }
        hours {
            day
            isOpen
            hours
        }
        timezone
        yearOpened
        yearClosed
        updatedDate
        createdDate
      }
    }
  }
`;
