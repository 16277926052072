import { guidGenerator } from "utilities";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { IToast } from "../types/IToast";

interface ToastState {
	toasts: IToast[];
}

const initialState: ToastState = { toasts: [] };

interface ToastStore extends ToastState {
	createToast: (toast: Omit<IToast, "id">) => IToast;
	addToast: (toast: Omit<IToast, "id">) => void;
	removeToast(id: string): void;
}

// @ts-ignore
export const useToasts = create<ToastStore>()(
	devtools(
		immer((set, get) => ({
			...initialState,

			createToast(toast: Omit<IToast, "id">): IToast {
				const id = guidGenerator();
				const newToast = {
					...toast,
					id,
					duration: toast?.duration || 6000,
				};
				return newToast;
			},

			/**
			 * Adds a toast to the store.
			 * @param toast The toast to add.
			 */
			addToast(toast: Omit<IToast, "id">) {
				const newToast = get().createToast(toast);
				const toastList = [...get().toasts];
				if (toastList.length > 2) {
					toastList.shift();
				}
				set(() => ({
					toasts: [...toastList, newToast],
				}));
				if (get().toasts.length > 0) {
					setTimeout(() => {
						set((state) => ({
							toasts: state.toasts.filter(
								(toast) => toast?.id !== newToast?.id,
							),
						}));
					}, toast?.duration);
				}
			},

			/**
			 * Removes a toast from the store.
			 * @param id The ID of the toast to remove.
			 */
			removeToast(id: string) {
				set((state) => ({
					toasts: state.toasts.filter((toast) => toast.id !== id),
				}));
			},
		})),
		{ name: "useToasts" },
	),
);

export const toastActions = {
	addToast: useToasts.getState().addToast,
	removeToast: useToasts.getState().removeToast,
};
