const updateLocationProductRelationship = /* GraphQL */ `
mutation CreateLocationProductRelationship($input: inputLocationProductRelationship!) {
    createLocationProductRelationship(input: $input) {
      available
      canned
      growler
      location {
        id
        title
      }
      onTap
      product {
        id
        title
      }
    }
  }`;
export default updateLocationProductRelationship;
