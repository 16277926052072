export const allStylesQuery = /* GraphQL */ `
query GetStyles($input: inputGetAll) {
	getStyles(input: $input){
		items {
			abvMax
			abvMin
			acidity
			alcohol
			alcoholBase
			alcoholFree
			appearance
			appleVariety
			baseSpirit
			beanRegion
			beanType
			blxGroup
			body
			brixCount
			carbonation
			category {
				title
			}
			ciderColor
			ciderFamily
			cocktailColor
			coffeePreparation
			colorIntensity
			createdBy
			createdDate
			description
			fgMax
			fgMin
			finish
			flavorIntensity
			foodPairingList {
				title
			}
			fruitOrigin
			garnish
			grapesOrigin
			hintsOfFlavorNotes
			honeyVariety
			hops
			ibuMax
			ibuMin
			id
			kegFreshnessDuration
			kombuchaFamily
			label
			lastUpdatedBy
			malt
			meadColor
			mixer
			ogMax
			ogMin
			pearVariety
			perceivedBitterness
			perceivedProductBitterness
			perceivedSaltiness
			perceivedSourness
			perceivedSpiciness
			perceivedSweetness
			primaryFlavorNotes
			probiotics
			process
			productType
			roastProfile
			seltzerColor
			servingTemperature
			servingVessels
			slug
			sourness
			srmMax
			srmMin
			tannin
			teaCharacter
			title
			updatedDate
			vintage
			wineColor
			yeast
		}
		nextToken
		returnedCount
		totalResults
	}
}
`;
