import { toastActions } from "notifications/src/toasts";
import {
	BrewMenu,
	BrewMenuConnection,
	InputBrewMenuItemOrSection,
	InputByBmSubId,
	InputCreateBrewMenu,
	InputDeleteBrewMenu,
	InputGetBrewMenus,
	InputGetById,
	InputUpdateBrewMenu,
} from "types";
import { datadogLocalErrorLog } from "../../datadog/datadogErrorLogger";
import { addMenuItemOrSectionToBrewMenuMutation } from "../../mutations/private/brewmenu/addMenuItemOrSectionToBrewMenuMutation";
import { createBrewMenuMenu } from "../../mutations/private/brewmenu/createBrewMenuMenu";
import { deleteBrewMenu } from "../../mutations/private/brewmenu/deleteBrewMenu";
import { updateBrewMenu } from "../../mutations/private/brewmenu/updateBrewMenu";
import { getBrewMenuByIdQuery } from "../../queries/private/brewmenu/getBrewMenuByIdQuery";
import { getBrewMenusByBMSubIdQuery } from "../../queries/private/brewmenu/getBrewMenusByBMSubIdQuery";
import { getBrewMenusQuery } from "../../queries/private/brewmenu/getBrewMenusQuery";
import { baseApi } from "../baseApi";

const brewMenuMenuApiLog = datadogLocalErrorLog("brewMenuMenuApi");

const brewMenuMenuApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		/**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrewMenuById  */
		getBrewMenuById: builder.query<BrewMenu, InputGetById>({
			providesTags: (result) => {
				return result
					? [{ type: "MenuById", id: result.id }]
					: [{ type: "MenuById", id: "LIST" }];
			},
			query: (input) => ({
				query: getBrewMenuByIdQuery,
				args: input,
			}),
			transformResponse: (data: unknown): BrewMenu => {
				// TODO: Add notifications
				return data as BrewMenu;
			},
			transformErrorResponse: brewMenuMenuApiLog("getBrewMenuById - Testing"),
		}),
		/**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrewMenus  */
		getBrewMenus: builder.query<BrewMenuConnection, InputGetBrewMenus>({
			//! NEEDS: TESTING
			providesTags: ["Menus"],
			query: (input) => ({
				query: getBrewMenusQuery,
				args: input,
			}),
			transformResponse: (data: BrewMenuConnection): BrewMenuConnection => {
				// TODO: Add notifications
				//! NEEDS: brewMenuActions
				return data;
			},
			transformErrorResponse: brewMenuMenuApiLog("getBrewMenus"),
		}),
		/**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrewMenus  */
		getBrewMenusByBMSubId: builder.query<BrewMenuConnection, InputByBmSubId>({
			//! NEEDS: TESTING
			providesTags: ["Menus"],
			query: (input) => ({
				query: getBrewMenusByBMSubIdQuery,
				args: input,
			}),
			transformResponse: (data: BrewMenuConnection): BrewMenuConnection => {
				// TODO: Add notifications
				//! NEEDS: brewMenuActions
				return data;
			},
			transformErrorResponse: brewMenuMenuApiLog("getBrewMenusByBMSubId"),
		}),
		/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/addMenuItemOrSectionToBrewMenu */
		addMenuItemOrSectionToBrewMenu: builder.mutation<
			{
				id: BrewMenu["id"];
				status: BrewMenu["status"];
				title: BrewMenu["title"];
				activeDates: BrewMenu["activeDates"];
				locationId: BrewMenu["locationId"];
			},
			InputBrewMenuItemOrSection
		>({
			query: (input) => ({
				query: addMenuItemOrSectionToBrewMenuMutation,
				args: input,
			}),
			transformErrorResponse: brewMenuMenuApiLog(
				"addMenuItemOrSectionToBrewMenu",
			),
			invalidatesTags: (result) => {
				return result
					? [{ type: "MenuById", id: result.id }]
					: [{ type: "MenuById", id: "LIST" }];
			},
		}),
		/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/createBrewMenu */
		createBrewMenu: builder.mutation<BrewMenu, InputCreateBrewMenu>({
			//! NEEDS: TESTING
			query: (input) => ({
				query: createBrewMenuMenu,
				args: input,
			}),
			transformResponse: (data: unknown): BrewMenu => {
				//! NEEDS: brewMenuActions
				if (data) {
					toastActions.addToast({
						title: "Success",
						description: "Brew Menu Created",
						variant: "success",
					});

					return data as BrewMenu;
				}
			},
			transformErrorResponse: (error, meta) => {
				toastActions.addToast({
					title: "Error",
					description: "Brew Menu Creation Failed",
					variant: "error",
				});
				brewMenuMenuApiLog("createBrewMenu")(error, meta);
			},
			invalidatesTags: ["Menus"],
		}),
		/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/deleteBrewMenu */
		deleteBrewMenu: builder.mutation<BrewMenu[], InputDeleteBrewMenu>({
			//! NEEDS: TESTING
			query: (input) => ({
				query: deleteBrewMenu,
				args: input,
			}),
			transformResponse: (data: BrewMenu[]): BrewMenu[] => {
				// TODO: Add notifications
				//! NEEDS: brewMenuActions
				return data;
			},
			transformErrorResponse: brewMenuMenuApiLog("deleteBrewMenu"),
			invalidatesTags: ["Menus"],
		}),
		/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updateBrewMenu */
		updateBrewMenu: builder.mutation<BrewMenu, InputUpdateBrewMenu>({
			//! NEEDS: TESTING
			query: (input) => ({
				query: updateBrewMenu,
				args: input,
			}),
			transformResponse: (data: unknown): BrewMenu => {
				// TODO: Add notifications
				//! NEEDS: brewMenuActions
				return data as BrewMenu;
			},
			transformErrorResponse: brewMenuMenuApiLog("updateBrewMenu"),
			invalidatesTags: (result) => [
				{ type: "MenuById", id: "LIST" },
				{ type: "MenuById", id: result.id },
				"BrewMenuSubscription",
				"Menus",
			],
		}),
	}),
});

const {
	useAddMenuItemOrSectionToBrewMenuMutation,
	useCreateBrewMenuMutation,
	useDeleteBrewMenuMutation,
	useGetBrewMenuByIdQuery,
	useGetBrewMenusByBMSubIdQuery,
	useGetBrewMenusQuery,
	useLazyGetBrewMenuByIdQuery,
	useLazyGetBrewMenusQuery,
	useUpdateBrewMenuMutation,
} = brewMenuMenuApi;

export {
	brewMenuMenuApi,
	/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/addMenuItemOrSectionToBrewMenu */
	useAddMenuItemOrSectionToBrewMenuMutation,
	/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/createBrewMenu */
	useCreateBrewMenuMutation,
	/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/deleteBrewMenuItems */
	useDeleteBrewMenuMutation,
	/**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrewMenuById  */
	useGetBrewMenuByIdQuery,
	/**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrewMenusByBMSubIdQuery  */
	useGetBrewMenusByBMSubIdQuery,
	/**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrewMenus  */
	useGetBrewMenusQuery,
	/**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrewMenuById  */
	useLazyGetBrewMenuByIdQuery,
	/**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrewMenus  */
	useLazyGetBrewMenusQuery,
	/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updateBrewMenu */
	useUpdateBrewMenuMutation
};

