export const deleteTapSensorMutation = /* GraphQL */ `
mutation SuperAdminDeleteTapSensor($input: inputSuperAdminDeleteTapSensor!) {
  superAdminDeleteTapSensor(input: $input) {
        __typename
        id
        createdDate
        updatedDate
        createdBy
        lastUpdatedBy
        gatewayDeviceId
        sensorDeviceId
        voltage
        barTitle
        tapNumber
        title
        position
        description
        model
        firmware
        connectionStatus
        status
        tappedKeg {
            id
            cooler
            coupler
            cost
            kegStatus
            depletionStats {
                depletionPace
                depletionRate
                projectedKickDate
                depletionEstimates {
                    date
                    fluidOz
                }
            }
            updatedDate
            servingSizes
            defaultServingSize
            vessel {
                slug
                iconSlug
                label
                shortTitle
            }
            product {
                __typename
                id
                title
                abv
                assets {
                    __typename
                    id
                    alt
                    type
                    caption
                    ... on S3Asset {
                        path
                    }
                    ... on CloudinaryAsset {
                        cloudinaryId
                    }
                }
                color
                description
                primaryFlavorNotes
                hintsOfFlavorNotes
                traits {
                    nutrition {
                        calories
                        fat
                        saturatedFat
                        transFat
                        cholesterol
                        sodium
                        carbohydrates
                        dietaryFiber
                        sugars
                        protein
                    }
                    allergens
                    otherTraits
                    availability
                }
                servingTemperature
                servingVessels
                ingredients {
                    type
                    title
                }
                brand {
                    id
                    slug
                    title
                    displayTitle
                    address {
                        city
                        state
                    }
                    assets {
                        path
                        type
                        alt
                    }
                }
                tastingExperience
                ... on Beer {
                    ibu
                    srm
                    perceivedBitterness
                    style {
                        abvMax
                        abvMin
                        createdDate
                        description
                        fgMax
                        fgMin
                        servingVessels
                        hintsOfFlavorNotes
                        hops
                        ibuMax
                        ibuMin
                        id
                        label
                        malt
                        ogMax
                        ogMin
                        primaryFlavorNotes
                        servingTemperature
                        slug
                        srmMax
                        srmMin
                        title
                        updatedDate
                        yeast
                    }
                    
                }
                ... on Kombucha {
                    style {
                        id
                        slug
                        title
                    }
                    appearance
                    acidity
                    body
                    brixCount
                    carbonation
                    kombuchaFamily
                    perceivedSweetness
                    probiotics
                    sourness
                    teaCharacter
                }
                ... on Cider {
                    id
                    slug
                    title
                    acidity
                    body
                    carbonation
                    ciderFamily
                    perceivedSweetness
                    tannin
                }
            }
            initialFluidOz
            currentFluidOz
            isTemporary
            cost
            createdDate
            id
            receivedDate
            isPartial
            isEstimate
            tappedDate
            servingSizes
            defaultServingSize
        }
        assignedKegs {
            actualKickDate
            cooler
            coupler
            currentFluidOz
            id
            initialFluidOz
            kegStatus
            isTemporary
            receivedDate
            lastUpdatedBy
            assignedPositionIndex
            product {
                __typename
                id
                title
                color
                assets {
                    path
                    alt
                    type
                }
                brand {
                    id
                    title
                    displayTitle
                    assets {
                        __typename
                        id
                        alt
                        type
                        caption
                        ... on S3Asset {
                            path
                        }
                        ... on CloudinaryAsset {
                            cloudinaryId
                        }
                    }
                }
                ... on UnverifiedProduct {
                    brandName
                }
                ... on Beer {
                    id
                    abv
                    ibu
                    srm
                    style {
                        title
                        id
                        abvMax
                        abvMin
                        fgMax
                        fgMin
                        ibuMax
                        ibuMin
                        hops
                        malt
                        ogMax
                        ogMin
                        srmMax
                        srmMin
                        yeast
                    }
                    title
                }
                ... on Kombucha {
                    style {
                        id
                        slug
                        title
                    }
                    appearance
                    acidity
                    body
                    brixCount
                    carbonation
                    kombuchaFamily
                    perceivedSweetness
                    probiotics
                    sourness
                    teaCharacter
                }
                ... on Cider {
                    id
                    slug
                    title
                    acidity
                    body
                    carbonation
                    ciderFamily
                    perceivedSweetness
                    tannin
                }
            }
            vessel {
                title
                slug
                iconSlug
                shortTitle
                material
            }
            depletionStats {
                depletionPace
                depletionRate
                projectedKickDate
                depletionEstimates {
                    date
                    fluidOz
                }
            }
            cost
            tappedDate
            updatedDate
        }
    }
}
`;
