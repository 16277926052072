export const superAdminUpdateUserMutation = /* GraphQL */ `
mutation SuperAdminUpdateUser($input: inputSuperAdminUpdateUser!) {
	superAdminUpdateUser(input: $input){
		updatedDate
		isActive
		id
		createdDate
		address {
			city
			country
			# geo { ...geo }
			postalCode
			state
			street
			street2
		}
		bdbAccess
		bmSubscriptions {
			bmSubId
			createdBy
			createdDate
			lastUpdatedBy
			role
			updatedDate
		}
		boards {
			createdBy
			createdDate
			# favorites { ...favorites }
			id
			isPrivate
			lastUpdatedBy
			# routes { ...routes }
			title
			updatedDate
		}
		brewlogixSuperadmin
		contact {
			email
			phone
		}
		contactPreference
		createdBy
		defaultSubscription
		description
		familyName
		favorites {
			brands
			# favoriteBrands { ...favoriteBrands }
			# favoriteLocations { ...favoriteLocations }
			# favoriteProducts { ...favoriteProducts }
			# locations { ...locations }
			# products { ...products }
		}
		givenName
		jobTitle
		lastUpdatedBy
		meta
		mmbBrands {
			brandId
			createdBy
			createdDate
			lastUpdatedBy
			role
			updatedDate
		}
		organization {
			createdBy
			createdDate
			lastUpdatedBy
			organizationId
			role
			updatedDate
		}
		ppSubscriptions {
			createdBy
			createdDate
			lastUpdatedBy
			ppSubId
			role
			updatedDate
		}
		registrationSource
		routes {
			boardAssociations
			# coordinates { ...coordinates }
			createdBy
			createdDate
			description
			distance
			duration
			id
			isActive
			lastUpdatedBy
			notes
			routeId
			title
			updatedDate
		}
		slug
		trustedUser
	}
}
`;
