import {
	CreateVessel,
	DeleteVessel,
	InputGetById,
	UpdateVessel,
	Vessel,
} from "types";
import { createVesselMutation } from "../mutations/private/vessel/createVesselMutation";
import { deleteVesselMutation } from "../mutations/private/vessel/deleteVesselMutation";
import { updateVesselMutation } from "../mutations/private/vessel/updateVesselMutation";
import { getVesselByIdQuery } from "../queries/private/vessel/getVesselByIdQuery";
import { getVesselsQuery } from "../queries/private/vessel/getVesselsQuery";
import { baseApi } from "./baseApi";

const vesselApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getVesselById: builder.query<Vessel, InputGetById>({
			providesTags: ["Vessels"],
			query: (input) => ({
				query: getVesselByIdQuery,
				args: input,
			}),
		}),
		getVessels: builder.query<Vessel[], void>({
			providesTags: ["Vessels"],
			query: (input) => ({
				query: getVesselsQuery,
				args: { limit: 750 },
			}),
			transformResponse: (response) => response?.items || [],
		}),
		updateVessel: builder.mutation<void, UpdateVessel>({
			invalidatesTags: ["Vessels"],
			query: (input) => ({
				query: updateVesselMutation,
				args: input,
			}),
		}),
		createVessel: builder.mutation<void, CreateVessel>({
			invalidatesTags: ["Vessels"],
			query: (input) => ({
				query: createVesselMutation,
				args: input,
			}),
		}),
		deleteVessel: builder.mutation<void, DeleteVessel>({
			invalidatesTags: ["Vessels"],
			query: (input) => ({
				query: deleteVesselMutation,
				args: input,
			}),
		}),
	}),
});

const {
	useGetVesselByIdQuery,
	useGetVesselsQuery,
	useUpdateVesselMutation,
	useCreateVesselMutation,
	useDeleteVesselMutation,
} = vesselApi;

export {
	useCreateVesselMutation,
	useDeleteVesselMutation,
	useGetVesselByIdQuery,
	useGetVesselsQuery,
	useUpdateVesselMutation,
	vesselApi,
};
