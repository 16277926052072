export const updateBrandMutation = /* GraphQL */ `
mutation UpdateBrand($input: updateBrand!) {
    updateBrand(input: $input) {
        address {
            city
            country
            postalCode
            state
            street
            street2
        }
        featuredBrew {
            id
            title
            slug
        }
        assets {
            alt
            brand {
                id
                lastUpdatedBy
                slug
                title
                displayTitle
            }
            createdBy
            createdDate
            id
            lastUpdatedBy
            locations {
                id
                title
                slug
            }
            path
            products {
                id
                title
                slug
            }
            tags
            type
            updatedDate
            bytes
            width
            height
            ... on S3Asset {
                path
                filename
            }
            ... on CloudinaryAsset {
                cloudinaryId
                caption
                category
            }
        }
        baId
        contact {
            email
            phone
        }
        createdBy
        createdDate
        description
        displayTitle
        guid
        id
        lastUpdatedBy
        slug
        socialMedia {
            handle
            platform
        }
        subtitle
        title
        traits {
            brandTypes
            brewTypes
            status
        }
        updatedDate
        website
        yearEstablished
    }
}
`;
