export const updateMenuItem = /* GraphQL */ `mutation UpdateMenuItem($input: inputUpdateMenuItem!) {
	updateMenuItem(input: $input){
        id
		isVisible
		servings {
			isActive
			isDefault
			label
			oz
			price
			servingType
		}
	} 
}`;
