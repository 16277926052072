export const getOrganizationPricingPresetById = /* GraphQL */ `query GetOrganizationPricingPresetById($input: inputGetOrganizationPricingPresetById!) {
	getOrganizationPricingPresetById(input: $input){
		createdBy
		createdDate
		id
		isDefault
		lastUpdatedBy
		servings {
			isActive
			isDefault
			isDisplayed
			label
			oz
			pourSize
			price
			salePrice
			servingType
			showServingType
		}
		title
		updatedDate
	}
}`;
