import { MenuSection } from "types";
import { useMenuItemsAndSectionMutations } from "../menuItemsAndSections/useMenuItemsAndSectionMutations";

/*
 * Hook to update Hidden Sections field in the Sections Object
 * Iterates through all menuSections in a menu and determines whether changes have occured
 * If change has occured, the section is updated
 * If changes have not occured it moves on to the next section
 */
export const useUpdateHiddenSections = () => {
	const { updateHiddenSectionsOnMenuSection } =
		useMenuItemsAndSectionMutations();
	const updateHiddenSections = async (
		hiddenSections: string[],
		displayId: string,
		menuSections: MenuSection[],
	) => {
		await Promise.all(
			menuSections.map(async ({ title, id, menuItems, hiddenOnDisplays }) => {
				let isUpdatingHiddenOnDisplays = false;

				let newHiddenArray = [];
				const menuItemIds = menuItems?.map((item) => item?.id);

				// First check to see if hiddenOnDisplays exists on the section object
				if (hiddenOnDisplays) {
					/*
					 * 1) If hiddenOnDisplays includes the current display ID,
					 * 2) BUT the section ID no longer resides in state (hiddenSections) then the section should no longer be hidden.
					 * We filter out the display id from hiddenOnDisplays and pass this new array to the update query.
					 */
					if (
						hiddenOnDisplays.includes(displayId) &&
						!hiddenSections.includes(id)
					) {
						newHiddenArray = hiddenOnDisplays.filter(
							(hiddenId) => hiddenId !== displayId,
						);
						isUpdatingHiddenOnDisplays = true;
						/*
						 * 1) If hiddenOnDisplays does not include the current display ID,
						 * 2) BUT the section ID exists in state (hiddenSections) then the section should be hidden.
						 * We grab any other display IDs that might live in hiddenOnDisplays and then pop the current display on the end of the array to pass to the update query.
						 */
					} else if (
						!hiddenOnDisplays.includes(displayId) &&
						hiddenSections.includes(id)
					) {
						newHiddenArray = [...hiddenOnDisplays, displayId];
						isUpdatingHiddenOnDisplays = true;
					}
				} else {
					/*
					 * 1) If the hiddenOnDisplays object does not exist on the section
					 * 2) BUT, the section ID exists in state (hiddenSections)
					 * We send the displayId in an array to the update query
					 */
					if (hiddenSections.includes(id)) newHiddenArray = [displayId];
					isUpdatingHiddenOnDisplays = true;
				}

				if (isUpdatingHiddenOnDisplays)
					await updateHiddenSectionsOnMenuSection(
						id,
						menuItemIds,
						title,
						newHiddenArray,
					);
			}),
		);
	};

	return {
		updateHiddenSections,
	};
};
