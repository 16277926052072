"use client";

import { useEffect, useState } from "react";
import { BrewMenuSubscription, User } from "types";
import { useLazyGetBLXUserQuery } from "../../api";
import { useAppSelector } from "../store";
import {
	activeBmaSub,
	activeBrandSub,
	activeSubs,
	sessionUser,
} from "./persistedUserSlice";
import { IUserActions, IUserState, userActions, userState } from "./userState";

export interface UseUserHook extends Partial<IUserState & IUserActions> {
	userData: User;
	activeBmaSubId: string;
	isFetchingUserData: boolean;
	activeBrandId: string;
	activePPSubId: string;
	sessionUser: User;
	associatedBMSubscriptions: BrewMenuSubscription[];
}

export const useBLXUser = (user?: User): UseUserHook => {
	const [blxUser, setBlxUser] = useState<User>();

	const [getUser] = useLazyGetBLXUserQuery();

	const associatedPPSubscriptions = userState.use.associatedPPSubscriptions();
	const associatedBrands = userState.use.associatedBrands();
	const associatedBMSubscriptions = userState.use.userBMAssociations();

	const resetUserState = userState.use.resetUserState();
	const populateUserState = userActions.populateUserState;

	// RTK User State
	const userData = useAppSelector(sessionUser);
	const activePPSubId = useAppSelector(activeSubs).pp;
	const activeBrandId = useAppSelector(activeBrandSub);
	const activeBmaSubId = useAppSelector(activeBmaSub);

	useEffect(() => {
		if (user || (userData && userData?.id.length > 0)) {
			populateUserState({ user: user ?? userData });
			setBlxUser(user ?? userData);
		} else {
			(async () => {
				const userRes = await getUser().unwrap();
				if (userRes.id) {
					setBlxUser(userRes);
					populateUserState({ user: userRes });
				}
			})();
		}

		return () => {
			resetUserState();
			setBlxUser(null);
		};
	}, [userData]);

	// Zustand User State
	const appAccess = userState.use.appAccess();
	const orgId = userState.use.orgId();
	const orgRole = userState.use.orgRole();
	const userRole = userState.use.userRole();

	return {
		sessionUser: userData,
		activeBmaSubId,
		activeBrandId,
		activePPSubId,
		resetUserState,
		userData: user ?? blxUser,
		appAccess,
		associatedBrands,
		associatedPPSubscriptions,
		associatedBMSubscriptions,
		isFetchingUserData: false,
		orgId,
		orgRole,
		userRole,
	};
};
