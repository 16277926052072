export const getBrewMenuDisplayBySlugsQuery = /* GraphQL */ `query GetBrewMenuDisplayBySlugs($input: inputGetBySlug!) {
	getBrewMenuDisplayBySlugs(input: $input){
		createdBy
		createdDate
		displayTitle
		displayType
		id
		lastUpdatedBy
		menu {
			activeDates
			createdBy
			createdDate
			id
			lastUpdatedBy
			locationId
			ppSubId
			status
			title
			updatedDate
		}
		settings {
			allowPagination
			backgroundColor
			colorScheme
			columns
			fallbackImage
			font
			numberOfItemsPerScreen
			orientation
			primaryFontColor
			productImage
			rotationInterval
			screenMode
			secondaryFontColor
			sectionBackgroundColor
			sectionFontColor
			showABV
			showIBU
			showLogoOnAllPages
			showOnDeck
			showPrimaryFlavorNotes
			showSection
			showStyle
			showUnknownKegs
			tertiaryFontColor
		}
		slug
		status
		title
		updatedDate
	}
}`;
