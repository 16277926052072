import { sortProductsByFields } from "utilities";
import { useProductSearchStore } from "./productSearchStore";
import { useProductSearch } from "./useProductSearch";

export const useSortedProductSearch = () => {
	const { selectedResults } = useProductSearch();
	const { sortDir, sortingBy } = useProductSearchStore();

	if (sortingBy === null) return selectedResults || [];

	const sortedResults = sortProductsByFields(
		[...selectedResults],
		sortDir,
		sortingBy,
	);

	return sortedResults || [];
};
