export const getThroughputByProduct = /* GraphQL */ `
query getThroughputByProduct($input: inputGetThroughputByProduct!) {
  getThroughputByProduct(input: $input) {
    productThroughput {
      avgCost
      averageInitialFluidOz
      brandTitle
      productTitle
      styleTitle
      totalThroughput
      tapNumber
      tapSensorId
      timestamp
    }
  }
}
`;
