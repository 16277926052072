"use client";
import { useParams } from "next/navigation";
import { Keg } from "types";
import { useGetTappedKegsQuery } from "../../../api/inventoryApi";
import {
	InventoryState,
	inventoryActions,
	inventoryState,
} from "../inventoryState";

export interface UseTappedKegsHook extends Partial<InventoryState> {
	isFetching: boolean;
	isLoading: boolean;
	isSuccessTappedKegs: boolean;
	isError: boolean;
	tappedKegs: Keg[];
}

export const useTappedKegs = (): UseTappedKegsHook => {
	const selectedKeg = inventoryState.use.selectedKeg();
	const selectKeg = inventoryActions.selectKeg;

	const params = useParams<{ ppSubId: string }>();
	const activePPSubId = params?.ppSubId;

	const {
		data: tappedKegs,
		isLoading,
		isError,
		isFetching,
		isSuccess,
	} = useGetTappedKegsQuery(
		{ ppSubId: activePPSubId },
		{ skip: !activePPSubId, refetchOnMountOrArgChange: true },
	);

	return {
		tappedKegs,
		selectKeg,
		selectedKeg,
		isLoading,
		isSuccessTappedKegs: isSuccess,
		isError,
		isFetching,
	};
};
