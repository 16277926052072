const superAdminGetPPSubscriptionsQuery = /* GraphQL */ `
query SuperAdminGetPPSubscriptions($input: inputGetAll!) {
  superAdminGetPPSubscriptions(input: $input) {
    items {
      __typename
      priorityBrands {
        id
        title
        assets {
          path
        }
      }
      onlyPriorityBrands
      address {
        city
        country
        postalCode
        state
        street
        street2
      }
      hubSpotId
      createdBy
      createdDate
      expirationDate
      gateways {
        id
      }
      id
      organization {
        id
        title
      }
      lastUpdatedBy
      locationType
      numberOfTapSensors
      settings {
          batteryReplacementPlan
          coolers
          couplers
          daysOnHandGoal
          defaultServingSize
          freshnessThreshold
          inventoryTurnsGoal
          isActive
          newProductDefinition
          pricingMethod
          pricingPercentage
          productLifeTracking
          seatingCapacity
          servingSizes
          standardHours {
              day
              hours
              isOpen
          }
          tappedGoal
          timeZone
          varietyScoreGoal
          workWeekStartDay
      }
      slug
      tapSensors {
        id
      }
      title
      updatedDate
    }
    nextToken
    returnedCount
  }
}
`;

export default superAdminGetPPSubscriptionsQuery;
