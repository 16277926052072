export const getAllUsersQuery = /* GraphQL */ `
query SuperAdminGetUsers($input: inputSuperAdminGetUsers) {
  superAdminGetUsers(input: $input) {
    items {
      bdbAccess
      brewlogixSuperadmin
      trustedUser
      contact {
        email
        phone
      }
      createdDate
      familyName
      givenName
      id
      jobTitle
      lastUpdatedBy
      meta
      ppSubscriptions {
        ppSubId
        role
      }
      mmbBrands {
        brandId
        role
      }
      ppSubscriptions {
        ppSubId
        role
      }
      bmSubscriptions {
        bmSubId
        role
      }
      organization {
        organizationId
        role
      }
      slug
      updatedDate
    }
    nextToken
    returnedCount
    totalResults
  }
}
`;
