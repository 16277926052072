import { DateTime } from "luxon";
import { TimeZone } from "types";
import { TimeMapping } from "utilities";

export const getAPriorDate = ({
	number,
	timezone = TimeZone.UsEastern,
}: {
	number: number;
	timezone?: string;
}) =>
	DateTime.now()
		.setZone(TimeMapping[timezone])
		.plus({ days: -`${number}` });
