import { createApi } from "@reduxjs/toolkit/query/react";
import { Amplify } from 'aws-amplify';
import amplifyConfig from '../config/amplifyConfig';
import { graphqlBaseQuery } from "../utilities/graphqlBaseQuery";

Amplify.configure(amplifyConfig);

export const baseApi = createApi({
	reducerPath: "baseApi",
	refetchOnReconnect: true,
	baseQuery: graphqlBaseQuery,
	tagTypes: [
		"ArchivedKegs",
		"AssignedKegs",
		"AssociatedBrands",
		"AssociatedSubscriptions",
		"BmaDisplayById",
		"BMSubs",
		"Brand",
		"Brands",
		"BrewMenuSubscription",
		"Discounts",
		"Displays",
		"Keg",
		"KegById",
		"Kegs",
		"Location",
		"Locations",
		"MediaLibrary",
		"MenuById",
		"Menus",
		"Messages",
		"OnDeckKegs",
		"Organization",
		"Organizations",
		"PPSub",
		"Pricing",
		"ProductLocations",
		"Products",
		"SuperAdminPPSubs",
		"TappedKegs",
		"TapSensor",
		"TapSensorEvents",
		"TapSensors",
		"ThroughputByKegId",
		"ThroughputByPpSubId",
		"ThroughPutByProduct",
		"throughput",
		"UnassignedKegs",
		"User",
		"Users",
		"Styles",
		"Vessels",
	],

	endpoints: (builder) => ({}),
});
