import type {
	BrewMenuCustomProductConnection,
	BrewMenuSubscription,
	BrewMenuSubscriptionConnection,
	DiscountPreset,
	DiscountPresetConnection,
	InputByBmSubId,
	InputCreateBrewMenuSubscription,
	InputCreateDiscountPreset,
	InputDeleteDiscountPreset,
	InputGetBrewMenuSubscriptionById,
	InputGetBrewMenuSubscriptions,
	InputGetById,
	InputGetBySlug,
	InputGetDiscountPresets,
	InputSuperAdminCreateBrewMenuSubscription,
	InputSuperAdminUpdateBrewMenuSubscription,
	InputUpdateBrewMenuSubscription,
	InputUpdateDiscountPreset,
	InputVerifySlug,
	TapSensorConnectionPublic,
	VerifiedSlug,
} from "types";
import { datadogLocalErrorLog } from "../datadog/datadogErrorLogger";
import { createBrewMenuSubscriptionMutation } from "../mutations/private/brewmenu/createBrewMenuSubscriptionMutation";
import { createDiscountPresetMutation } from "../mutations/private/brewmenu/createDiscountPresetMutation";
import { deleteDiscountPresetMutation } from "../mutations/private/brewmenu/deleteDiscountPreset";
import { getBrewMenuCustomProductsByBMSubId } from "../mutations/private/brewmenu/getBrewMenuCustomProductsByBMSubId";
import { superAdminCreateBrewMenuSubscriptionMutation } from "../mutations/private/brewmenu/superAdminCreateBrewMenuSubscription";
import { superAdminUpdateBrewMenuSubscriptionMutation } from "../mutations/private/brewmenu/superAdminUpdateBrewMenuSubscriptionMutation";
import { updateBrewMenuSubscription } from "../mutations/private/brewmenu/updateBrewMenuSubscription";
import { updateDiscountPresetMutation } from "../mutations/private/brewmenu/updateDiscountPreset";
import { getBrewMenuSubscriptionByIdQuery } from "../queries/private/brewmenu/getBrewMenuSubscriptionByIdQuery";
import getBrewMenuSubscriptions from "../queries/private/brewmenu/getBrewMenuSubscriptions";
import { getDiscountPresetByIdQuery } from "../queries/private/brewmenu/getDiscountPresetById";
import { getDiscountPresetsQuery } from "../queries/private/brewmenu/getDiscountPresets";
import { getDiscountPresetsByBMSubIdQuery } from "../queries/private/brewmenu/getDiscountPresetsByBMSubId";
import { getVerifiedSlug } from "../queries/private/brewmenu/getVerifiedSlug";
import { getBrewMenuSubscriptionBySlugPublicQuery } from "../queries/public/brewmenu/getBrewMenuSubscriptionBySlugPublicQuery";
import { getTapSensorsForBrewMenu } from "../queries/public/brewmenu/getTapsensorsForBrewMenu";
import { userActions } from "../state";
import { baseApi } from "./baseApi";

const brewMenuApiLog = datadogLocalErrorLog("brewMenuApi");

const brewMenuApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		// https://blx-docs-mono-dev.vercel.app/docs/private/query/getTapSensorsForBrewMenu
		getTapSensorsForBrewMenu: builder.query<
			TapSensorConnectionPublic,
			InputGetById
		>({
			query: (input) => ({
				query: getTapSensorsForBrewMenu,
				args: input,
			}),
			transformResponse: (
				data: TapSensorConnectionPublic,
			): TapSensorConnectionPublic => {
				// TODO: Add notifications
				//! NEEDS: brewMenuActions
				return data;
			},
			transformErrorResponse: brewMenuApiLog("getTapSensorsForBrewMenu"),
		}),
		/**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrewMenuSubscriptionById  */
		getBrewMenuSubscriptionById: builder.query<
			BrewMenuSubscription,
			InputGetBrewMenuSubscriptionById
		>({
			providesTags: ["BrewMenuSubscription", "Menus", "BMSubs"],
			query: (input) => ({
				query: getBrewMenuSubscriptionByIdQuery,
				args: input,
			}),
			transformResponse: (data: BrewMenuSubscription): BrewMenuSubscription => {
				userActions.setUserBMAssociations(data);
				// TODO: Add notifications
				//! NEEDS: brewMenuActions
				return data;
			},
			transformErrorResponse: brewMenuApiLog("getBrewMenuSubscriptionById"),
		}),
		/** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrewMenuSubscriptionBySlug  */
		getBrewMenuSubscriptionBySlug: builder.query<
			BrewMenuSubscription,
			InputGetBySlug
		>({
			//! NEEDS: TESTING
			providesTags: ["Menus"],
			query: (input) => ({
				query: getBrewMenuSubscriptionBySlugPublicQuery,
				args: input,
			}),
			transformResponse: (data: BrewMenuSubscription): BrewMenuSubscription => {
				// TODO: Add notifications
				//! NEEDS: brewMenuActions
				return data;
			},
			transformErrorResponse: brewMenuApiLog("getBrewMenuSubscriptionBySlug"),
		}),
		/** https://blx-docs-mono-dev.vercel.app/docs/private/query/getDiscountPresets */
		getDiscountPresets: builder.query<
			DiscountPresetConnection,
			InputGetDiscountPresets
		>({
			//! NEEDS: TESTING
			providesTags: ["Menus", "Discounts"],
			query: (input) => ({
				query: getDiscountPresetsQuery,
				args: input,
			}),
			transformResponse: (
				data: DiscountPresetConnection,
			): DiscountPresetConnection => {
				// TODO: Add notifications
				//! NEEDS: brewMenuActions
				return data;
			},
			transformErrorResponse: brewMenuApiLog("getDiscountPresets"),
		}),
		/** https://blx-docs-mono-dev.vercel.app/docs/private/query/getDiscountPresetsByBMSubId */
		getDiscountPresetsByBMSubId: builder.query<
			DiscountPresetConnection,
			InputByBmSubId
		>({
			providesTags: ["Menus", "Discounts"],
			query: (input) => ({
				query: getDiscountPresetsByBMSubIdQuery,
				args: input,
			}),
			transformResponse: (
				data: DiscountPresetConnection,
			): DiscountPresetConnection => {
				// TODO: Add notifications
				//! NEEDS: brewMenuActions
				return data;
			},
			transformErrorResponse: brewMenuApiLog("getDiscountPresetsByBMSubId"),
		}),
		/** https://blx-docs-mono-dev.vercel.app/docs/private/query/getDiscountPresetById */
		getDiscountPresetById: builder.query<DiscountPreset, InputGetById>({
			//! NEEDS: TESTING
			providesTags: ["Menus", "Discounts"],
			query: (input) => ({
				query: getDiscountPresetByIdQuery,
				args: input,
			}),
			transformResponse: (data: DiscountPreset): DiscountPreset => {
				// TODO: Add notifications
				//! NEEDS: brewMenuActions
				return data;
			},
			transformErrorResponse: brewMenuApiLog("getDiscountPresetById"),
		}),
		/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/createDiscountPreset */
		createDiscountPreset: builder.mutation<
			DiscountPreset,
			InputCreateDiscountPreset
		>({
			//! NEEDS: TESTING
			invalidatesTags: ["Discounts"],
			query: (input) => ({
				query: createDiscountPresetMutation,
				args: input,
			}),
			transformResponse: (data: DiscountPreset): DiscountPreset => {
				// TODO: Add notifications
				//! NEEDS: brewMenuActions
				return data;
			},
			transformErrorResponse: brewMenuApiLog("createDiscountPreset"),
		}),
		/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/createBrewMenuSubscription */
		createBrewMenuSubscription: builder.mutation<
			BrewMenuSubscription,
			InputCreateBrewMenuSubscription
		>({
			invalidatesTags: ["User"],
			query: (input) => ({
				query: createBrewMenuSubscriptionMutation,
				args: input,
			}),
			transformErrorResponse: brewMenuApiLog("createBrewMenuSubscription"),
		}),
		/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/deleteDiscountPreset */
		deleteDiscountPreset: builder.mutation<
			DiscountPreset,
			InputDeleteDiscountPreset
		>({
			//! NEEDS: TESTING
			invalidatesTags: ["Discounts"],
			query: (input) => ({
				query: deleteDiscountPresetMutation,
				args: input,
			}),
			transformResponse: (data: DiscountPreset): DiscountPreset => {
				// TODO: Add notifications
				//! NEEDS: brewMenuActions
				return data;
			},
			transformErrorResponse: brewMenuApiLog("deleteDiscountPreset"),
		}),
		/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/superAdminUpdateBrewMenuSubscription */
		superAdminUpdateBrewMenuSubscription: builder.mutation<
			BrewMenuSubscription,
			InputSuperAdminUpdateBrewMenuSubscription
		>({
			invalidatesTags: ["BMSubs"],
			query: (input) => ({
				query: superAdminUpdateBrewMenuSubscriptionMutation,
				args: input,
			}),
		}),
		/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updateDiscountPreset */
		updateDiscountPreset: builder.mutation<
			DiscountPreset,
			InputUpdateDiscountPreset
		>({
			//! NEEDS: TESTING
			invalidatesTags: ["Discounts"],
			query: (input) => ({
				query: updateDiscountPresetMutation,
				args: input,
			}),
			transformResponse: (data: DiscountPreset): DiscountPreset => {
				// TODO: Add notifications
				//! NEEDS: brewMenuActions
				return data;
			},
			transformErrorResponse: brewMenuApiLog("updateDiscountPreset"),
		}),
		/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updateBrewMenuSubscription */
		updateBrewMenuSubscription: builder.mutation<
			BrewMenuSubscription,
			InputUpdateBrewMenuSubscription
		>({
			query: (input) => ({
				query: updateBrewMenuSubscription,
				args: input,
			}),
			transformResponse: (data: BrewMenuSubscription): BrewMenuSubscription => {
				// TODO: Add notifications
				return data;
			},
			transformErrorResponse: (error) => {
				// TODO: Add notifications
				console.error(
					"RTK!/transformErrorResponse/updateBrewMenuSubscription",
					error,
				);
				return error;
			},
		}),
		getBrewMenuCustomProductsByBMSubId: builder.mutation<
			BrewMenuCustomProductConnection,
			InputByBmSubId
		>({
			query: (input) => ({
				query: getBrewMenuCustomProductsByBMSubId,
				args: input,
			}),
			transformResponse: (
				data: BrewMenuCustomProductConnection,
			): BrewMenuCustomProductConnection => {
				// TODO: Add notifications
				//! NEEDS: brewMenuActions
				return data;
			},
			transformErrorResponse: brewMenuApiLog(
				"getBrewMenuCustomProductsByBMSubId",
			),
		}),
		verifySlug: builder.query<VerifiedSlug, InputVerifySlug>({
			query: (input) => ({
				query: getVerifiedSlug,
				args: input,
			}),
			transformResponse: (data: VerifiedSlug): VerifiedSlug => {
				return data;
			},
			transformErrorResponse: brewMenuApiLog("verifySlug"),
		}),
		getBrewMenuSubscriptions: builder.query<
			BrewMenuSubscriptionConnection,
			InputGetBrewMenuSubscriptions
		>({
			providesTags: ["BMSubs"],
			query: (input) => ({
				query: getBrewMenuSubscriptions,
				args: input,
			}),
		}),
		superAdminCreateBrewMenuSubscription: builder.mutation<
			void,
			InputSuperAdminCreateBrewMenuSubscription
		>({
			invalidatesTags: ["BMSubs"],
			query: (input) => ({
				query: superAdminCreateBrewMenuSubscriptionMutation,
				args: input,
			}),
		}),
	}),
});

const {
	useCreateBrewMenuSubscriptionMutation,
	useCreateDiscountPresetMutation,
	useDeleteDiscountPresetMutation,
	useGetBrewMenuCustomProductsByBMSubIdMutation,
	useGetBrewMenuSubscriptionByIdQuery,
	useGetBrewMenuSubscriptionBySlugQuery,
	useGetDiscountPresetByIdQuery,
	useGetDiscountPresetsByBMSubIdQuery,
	useGetDiscountPresetsQuery,
	useLazyGetBrewMenuSubscriptionByIdQuery,
	useSuperAdminUpdateBrewMenuSubscriptionMutation,
	useUpdateBrewMenuSubscriptionMutation,
	useUpdateDiscountPresetMutation,
	useVerifySlugQuery,
	useGetBrewMenuSubscriptionsQuery,
	useSuperAdminCreateBrewMenuSubscriptionMutation,
} = brewMenuApi;

export {
	brewMenuApi,
	/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/createBrewMenuSubscription */
	useCreateBrewMenuSubscriptionMutation,
	/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/createDiscountPreset */
	useCreateDiscountPresetMutation,
	/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/deleteDiscountPreset */
	useDeleteDiscountPresetMutation,
	useGetBrewMenuCustomProductsByBMSubIdMutation,
	/**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrewMenuSubscriptionById  */
	useGetBrewMenuSubscriptionByIdQuery,
	/** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrewMenuSubscriptionBySlug  */
	useGetBrewMenuSubscriptionBySlugQuery,
	useGetBrewMenuSubscriptionsQuery,
	/** https://blx-docs-mono-dev.vercel.app/docs/private/query/getDiscountPresetById */
	useGetDiscountPresetByIdQuery,
	/** https://blx-docs-mono-dev.vercel.app/docs/private/query/getDiscountPresetsByBMSubId */
	useGetDiscountPresetsByBMSubIdQuery,
	/** https://blx-docs-mono-dev.vercel.app/docs/private/query/getDiscountPresets */
	useGetDiscountPresetsQuery,
	/**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrewMenuSubscriptionById  */
	useLazyGetBrewMenuSubscriptionByIdQuery,
	useSuperAdminCreateBrewMenuSubscriptionMutation,
	/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/superAdminUpdateBrewMenuSubscription */
	useSuperAdminUpdateBrewMenuSubscriptionMutation,
	/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updateBrewMenuSubscription */
	useUpdateBrewMenuSubscriptionMutation,
	/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updateDiscountPreset */
	useUpdateDiscountPresetMutation,
	/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updateBrewMenuSubscription */
	useVerifySlugQuery
};

