import { toastActions } from "notifications/src/toasts";
import { Keg, ServingSize } from "types";
import { useUpdateKegMutation } from "../../../api";
import { useBLXUser } from "../../../state";

/**
 *  Hook used by BM to update servings data on MenuItems containing a TapSensor
 * In effort to preserve data and not accidentally replace keg data other than servings
 * DO NOT add additional data to the mutation query
 */
export const useUpdateKegServings = () => {
	const [updateKegMutation] = useUpdateKegMutation();
	const { activePPSubId } = useBLXUser();

	const updateKegServings = async (
		kegId: Keg["id"],
		servings: ServingSize[],
	): Promise<Keg> => {
		try {
			const { data } = await updateKegMutation({
				kegId,
				ppSubId: activePPSubId,
				servings,
			});
			if (data) return data;
		} catch (error) {
			console.error(error);
			toastActions.addToast({
				title: "Error updating Keg servings",
				duration: 6000,
				variant: "error",
				hasCloseButton: true,
			});
		}
	};

	return {
		updateKegServings,
	};
};
